@media (max-width: 1440px) {
    /* html, body, .app-container, #root, .main-content, .inc-login{
        height: 100%;
    
    }*/
    /* body{
        zoom: .75;
    }  */
    body{
        font-size: 10px;
    }
    h1{
        font-size: 28px;
    }
    .inc-form input, .inc-form select{
        height: 40px;
    }   
    .inc-login .login-banner div {
        position: absolute;
        right: 50px;
        max-width: 430px;
        top: 90px;
    }
    .inc-login{
    min-height: 654px;   
     }
    .login-form-container{
        width: 330px;
    }
    .login-logo {
        padding-top: 45px;
        padding-left: 50px;
    }
    .login-logo span{
        font-size: 88px;
    }
    .login-logo span::after {
        bottom: 22px;
        right: -25px;
        width: 20px;
        height: 20px;
    }
    .login-logo div{
        margin-top: 100px;
    }
    .login-logo div p{
        font-size: 16px;
    }
    .login-text{
        margin-bottom: 40px;
    }
    .login-text h1{
        font-size: 26px;
    }
    .login-text p{
        font-size: 14px;
    }
    .login-form .btn{
        height: 40px;
        font-size: 14px;
    }
    .show-password{
        width: 20px;
    }
/* 

    .navbar-menu{
        width: 200px;
    }
    #top-bar{
        margin-left: 200px;
        width: calc(100% - 200px);
    }
    .navbar-brand-box, #top-bar{
        height: 50px;
    }
    .navbar-brand-box span{
        font-size: 35px;
    }
    .navbar-brand-box span::after{
        bottom: 15px;
        right: -13px;
        width: 10px;
        height: 10px;
    }
    .inc-navigation {
        font-size: 12px;
    }
    .inc-navigation a{
        padding: 0px 10px;
    }

    .header-profile-user .profile-user{
        width: 30px;
        height: 30px;
    }
    .body-container{
        margin-top: 50px;
        margin-left: 200px;
        padding: 15px;
    }
    .inc-card{
        padding: 15px;
        border-bottom: 15px;
    }
    .inc-card.ch-100 {
        height: calc(100vh - 80px);
    }
    .inc-slider{
        width: 650px;
    }

    .inc-card-table table{
        font-size: 12px;  
    }

    .device-info div strong{
        flex:none;
        flex-basis: 140px;
    }

    .icon-btn{
        width:25px;
        height:25px;
    }
    .icon-btn i{
        background-size: 11px !important;
    } */
    /* COMMON */
    .row {
        --bs-gutter-x: 9px;
    }
    .mb-4 {
        margin-bottom: 10px !important;
    }
    .inc-overview{
        gap: 10px;
    }
    .inc-col-1 .first-half{
        margin-bottom: 10px;
    }
    .inc-col-1 .first-half,
    .inc-col-1 .second-half{
        gap:10px;
    }   
    .inc-o-col-2 .inc-fault-client{
        margin-bottom: 10px;
        gap: 10px;
    }
    .inc-overview-grid{
        grid-gap: 10px;
    }
    .inc-grid-col-1{
        row-gap: 10px;
        grid-gap: 10px; 
        grid-template-columns: repeat(2, 1fr) 355px;
    }
    /* SIDE NAVIGATION */
    .navbar-brand-box img{
        width: 180px;
        margin-left: 10px;
    }
    .navbar-menu{
        width: 200px;
    }
    .navbar-brand-box{
        padding: 30px 0px;
    }
    .inc-navigation a,
    .inc-navigation button{
        height: 35px;
        padding: 0px 10px;
        font-size: 11px;
    }
    .inc-navigation button i.fa{
        font-size: 14px;
        right: 10px;
    }
    .inc-navigation .dropdown-menu{
        font-size: 11px;
        width: 180px;
    }
    .inc-navigation .dropdown-menu li a {
        height: 30px;
    }
    .icon{
        width: 20px !important;
        height: 20px !important;
        background-size: 16px !important;
        margin-right: 10px !important;
    }
    .icon.notifications{
        margin-right: 0px !important;
    }
    .inc-card-button .icon{
        margin-right: 0px !important;
        background-size: 15px !important;
    }

    /* HEADER */
    #top-bar{
        margin-left: 200px;
        width: calc(100% - 200px);
        height: 45px;
    }
    .header-title{
        font-size: 14px;
    }
    .header-title p {
        font-size: 12px;
    }
    .name-post strong {
        font-weight: 500;
        font-size: 11px;
    }
    .header-notification .icon {
        height: 16px !important;
        background-size: 12px !important;
    }
    .header-profile-user .profile-user{
        width: 25px;
        height: 25px;
        font-size: 10px;
    }
    .header-profile-user .drop{
        width: 10px;
    }

    /* BODY CONTAINER */
    .body-container {
        margin-left: 200px;
        margin-top: 45px;
        min-height: calc(100vh - 45px);
        padding: 10px;
    }
    .inc-card-title{
        height: 45px;        
    }
    .inc-card-title span.dropdown,
    .inc-card-title .dropdown-menu{
        font-size: 11px;
    }    
    .inc-card-title .dropdown-menu{
        max-width: 110px;
        min-width: 50px;
    }
    .inc-card-title h3{
        font-size: 12px;
    }
    .card-count{
        font-size: 14px;
    }
    .node-sec{
        margin-top: 25px;
    }
    .node-legends ul{
        font-size: 11px;
    }
    .inc-card h4 {
        font-size: 12px;
    }
    .client-legend ul{
        font-size: 11px;
        gap: 5px;
    }
    .client-legend ul li span{
        margin-right: 3px;
    }   
    .deviceCountChart .device-chart{
        /* height: 218px; */
    } 
    .deviceCountChart .c-chart .recharts-wrapper{
        height: 152px !important;
    }
    .edge-chart{
        height: 137px;
    }
    .edge-legend{
        font-size: 12px;
    }
    .edge-legend ul li span{
        margin-right: 5px;
    }
    .edge-legend strong{
        margin-left: 5px;
    }
    .on-trend{
        height: 185px !important;
    }
    .on-trend .recharts-wrapper{
        margin-top: 0px;
    }
    .alarm-chart .recharts-wrapper{
        width: 100% !important;
        height: 167px !important;
    }
    .alarm-chart .fault-legends{
        padding: 0px 5px 0px 10px;
    }
    .inc-sys-health{
        padding: 0px 10px;
        margin-bottom: 15px;
    }
    .health-chart,
    .health-chart .recharts-wrapper{
        height: 118px !important;
    }
    .health-chart .recharts-wrapper{
        position: relative;
        bottom: -15px;
    }
    .health-chart .recharts-responsive-container{
        margin-top: -20px;
        height: 196px !important;
    }
    .trends-legends{
        margin-top: 5px;
    }
    .trends-legends ul {
        font-size: 12px;
    }
    .fault-legends div strong{
        font-size: 14px;
    }
    .fault-legends div span{
        font-size: 12px;
    }
    .dr-status{
        font-size: 11px;
        margin: 10px 0px;
    }
    .inc-card-table table{
        font-size: 10px;
    }
    .inc-card.ch-100 {
        min-height: calc(100vh - 90px);
    }
    .inc-card-table {
        max-height: calc(100vh - 230px);
    }
    .nav-underline {
        font-size: 11px;
    }
    .card-top h3{
        font-size: 14px;
    }
    .audit-card-count h2{
        font-size: 20px;
    }
    .audit-chart {
        height: 150px;
    }
    .inc-card-body {
        padding: 15px;
    }
    .card-top {
        margin-bottom: 5px;
    }
    .card-top span{
        height: 30px;
        width: 30px;
        font-size: 18px;
    }
    .ota-card{
        padding: 0px 10px;
    }
    .ota-count div:first-child span img{
        height: 25px;
    }
    .ota-graphics .dtype-count div span,
    .ota-count div:last-child span{
        font-size: 11px;
        white-space: nowrap;
    }
    .ota-count div:last-child strong {
        font-size: 18px;
    }
    .ota-card{
        gap: 10px;
    }
    .ota-count div:first-child span{
        height: 45px;
        width: 45px;
    }
    .ota-card{
        height: 65px;
    }
    .ota-count div:last-child{
        line-height: 18px;
    }
    .ota-graphics .dtype-count div span{
        height: 24px;
        padding: 0px 15px;
    }
    .ota-graphics .dtype-count div strong {
        font-size: 16px;
    }
    .ota-filter div:first-child input {
        width: 300px;
        font-size: 12px;
    }
    .ota-filter div:first-child select {
        width: 200px;
        font-size: 12px;
    }
    .device-chart .c-legend{
        font-size: 12px;
    }
    #roleTab button, #discoverTab button{
        font-size: 14px;
    }
    .permission-type ul li{
        height: 45px;
    }
    .permission-name ul li{
        height: 50px;
    }
    .discover-form{
        max-width: 750px;
    }
    .discover-form .form-control, .form-select{        
            font-size: 14px;
    }
    .form-control, .form-select{
        font-size: 14px;
    }
    .custom-mail span[class*='css-1f'] + div{
        font-size: 14px;
    }
    .col-form-label, .form-label{
        font-size: 12px;
    }
    .text-btn, .btn-outline, .btn-fill{
        font-size: 12px;
    }
    .modal-title{
        font-size: 16px;
    }

    .inc-report .report-section .report-container{
        height: 68vh;
    }
    .report-container h4{
        font-size: 14px;
    }
    .report-container .mb-35 {
        margin-bottom: 28px;
    }
    .report-container p{
        font-size: 12px;
    }
    .report-container .form-label,
    .report-container .form-check-label{
        font-size: 12px;
    }
    .inc-report .report-tab div span {
        font-size: 12px;
    }
    .inc-report .report-tab div strong {
        font-size: 14px;
    }
    .step i.line{
        height: 65px;
    }  
    .dashboard-device-info .dashboard-device-detail .detail-list span{
        font-size: 10px;
    }
    .editssid-scroll{
        max-height: 70vh;
    }
    .port-box,
    .mxw-1260{
        max-width: 720px;
    }
    .device-port-detail{
        grid-template-columns: repeat(3, 1fr);
    }
    .sideTab-container.wired-sideTab {
        grid-template-columns: 240px 1fr;
    }
    .inc-side-tab ul li button{
        font-size: 12px;
    }
    .device-port-detail strong{
        font-size: 12px;
    }
    .device-port-detail span{
        font-size: 10px;
    }
    .port-status{
        font-size: 12px;
    }
    .port-cable{
        left: -10px;
    }
    .port-legend ul{
        font-size: 14px;
    }
    .copyright-text{
        font-size: 12px;
    }
    .manage-client{
        height: 220px;
    }
    .notification-item div strong{
        font-size: 12px;
        font-weight: 500;
    }
    .notification-title{
        font-size: 16px;
        font-weight: 500;
    }
    .notification-title span{
        font-size: 12px;
    }
    .accordion,
    .accordion-button{
        font-size: 13px;
    }
    .tab-form .switch-box{
        font-size: 12px;
    }
    .schedule-btn button{
        font-size: 12px;
        height: 22px;
        padding: 5px 8px;
    }
    .ice-switch{
        width: 34px;
        height: 16px;
    }
    .ice-switch .slider:before{
        height: 12px;
        width: 12px;
    }
    input:checked+.slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }
    .inc-breadcrumb {
        top: 25px;
        margin-left: 10px;
    }
}
@media only screen and (max-height: 500px) {
    .w-scroll{
        max-height: calc(100vh - 170px);
    }
}
@media only screen and (min-width: 1367px) and (max-width: 1680px) {
    .inc-login {
        min-height: 726px;
    }
    .inc-login .login-banner div{
        right: 50px;
        max-width: 480px
    }

}

@media only screen and (min-height: 770px) and (max-height: 900px) { 
    .deviceCountChart .c-chart .recharts-wrapper {
        height: 210px !important;
    }
    .alarm-chart .recharts-wrapper {
        height:225px !important;
    }
    .edge-chart {
        height: 200px;
    }
    .on-trend {
        height: 258px !important;
    }
    .health-chart, .health-chart .recharts-wrapper {
        height: 195px !important;
    }
    .inc-sys-health {
        margin-bottom: 25px;
    }
    .node-sec {
        margin-top: 50px;
    }

}
