
.icon {
    position: relative;
    width: 28px;
    height: 28px;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: 20px !important;
    display: inline-block;
    margin-right: 3px
}

.icon.icon-big {
    background-size: 40px !important;
    width: 45px;
    height: 45px
}

.icon.icon-80 {
    background-size: 33px !important;
    width: 60px;
    height: 60px
}

.icon.home {
    background: url(../images/home.svg)
}

.icon.supervisor_account {
    background: url(../images/customer.svg)
}

.icon.domain {
    background: url(../images/assets.svg)
}

.icon.fault {
    background: url(../images/device-fault.svg)
}

.icon.alpha-d-box {
    background: url(../images/device.svg)
}

.icon.devices_other {
    background: url(../images/devices.svg)
}

.icon.settings_ethernet {
    background: url(../images/chain.svg)
}

.icon.dashboard {
    background: url(../images/dashboard.svg)
}

.icon.settings_input_antenna {
    background: url(../images/reports.svg)
}

.icon.settings {
    background: url(../images/settings.svg)
}

.icon.now_widgets {
    background: url(../images/widgets.svg)
}

.icon.memory {
    background: url(../images/ota.svg)
}

.icon.view_quilt {
    background: url(../images/entity.svg)
}

.icon.router {
    background: url(../images/edge_instances.svg)
}

.icon.history {
    background: url(../images/version_control.svg)
}

.icon.track_changes {
    background: url(../images/audit_logs.svg)
}

.icon.copy {
    background: url(../images/content_copy.svg)
}

.icon.settings_applications {
    background: url(../images/settings.svg)
}

.icon.folder {
    background: url(../images/reso_library.svg)
}

.icon.manage_history {
    background: url(../images/repo_setting.svg)
}

.icon.settings_backup_restore {
    background: url(../images/auto_commit.svg)
}

.icon.insert_chart {
    background: url(../images/api-usages.svg)
}

.icon.filter_list {
    background: url(../images/filter.svg)
}

.icon.search {
    background: url(../images/search.svg)
}

.icon.playlist_add {
    background: url(../images/enrichment.svg)
}

.icon.transform {
    background: url(../images/transformation.svg)
}

.icon.flash_on {
    background: url(../images/action.svg)
}

.icon.cloud_upload {
    background: url(../images/external.svg)
}

.icon.input {
    background: url(../images/input.svg)
}

.icon.edit {
    background: url(../images/edit.svg)
}

.icon.close {
    background: url(../images/close.svg)
}

.icon.flow {
    background: url(../images/flow.svg)
}

.icon.find_replace {
    background: url(../images/change_originator.svg)
}

.icon.email {
    background: url(../images/email.svg)
}

.icon.add_circle {
    background: url(../images/assign.svg)
}

.icon.notifications_off {
    background: url(../images/notifications-off.svg)
}

.icon.notifications {
    background: url(../images/notifications.svg)
}

.icon.content_copy {
    background: url(../images/content_copy.svg)
}

.icon.notifications_active {
    background: url(../images/create_alarm.svg)
}

.icon.pause {
    background: url(../images/delay.svg)
}
.icon.plus {
    background: url(../images/plus.svg)
}

.icon.upload {
    background: url(../images/upload.svg)
}

.icon.download {
    background: url(../images/download.svg)
}

.icon.bin {
    background: url(../images/bin.svg)
}

.icon.refresh {
    background: url(../images/refresh.svg)
}


.icon.remove_circle {
    background: url(../images/clear.svg)
}

.icon.repeat {
    background: url(../images/generator.svg)
}

.icon.menu {
    background: url(../images/log.svg)
}

.icon.functions {
    background: url(../images/message.svg)
}

.icon.cloud_download {
    background: url(../images/cloud.svg)
}

.icon.file_upload {
    background: url(../images/save.svg)
}

.icon.call_merge {
    background: url(../images/reply.svg)
}

.icon.call_made {
    background: url(../images/request.svg)
}

.icon.send {
    background: url(../images/send-email.svg)
}

.icon.sms {
    background: url(../images/sms.svg)
}

.icon.call_split {
    background: url(../images/call_split.svg)
}

.icon.file_download {
    background: url(../images/download_file.svg)
}

.icon.user-profile {
    background: url(../images/user_profile.svg)
}

.icon.password {
    background: url(../images/password.svg)
}

.icon.logout {
    background: url(../images/logout.svg)
}

.icon.up {
    background: url(../images/up.svg)
}

.icon.down {
    background: url(../images/down.svg)
}

.icon.account_circle {
    background: url(../images/user_profile.svg)
}

.icon.delete {
    background: url(../images/delete.svg)
}

.icon.assignment_ind,
.icon.share {
    background: url(../images/share.svg)
}

.icon.assignment_ind {
    background: url(../images/assign.svg)
}

.icon.assignment_return {
    background: url(../images/reply.svg)
}

.icon.reply {
    background: url(../images/private.svg)
}

.icon.security {
    background: url(../images/security.svg)
}

.icon.flag {
    background: url(../images/default.svg)
}

.icon.done {
    background: url(../images/check.svg)
}

.icon.help {
    background: url(../images/help.svg)
}

.icon.swap_calls {
    background: url(../images/queue.svg)
}

.icon.two-factor-authentication {
    background: url(../images/two-fa.svg)
}

.icon.tag {
    background: url(../images/tag.svg)
}

.icon.wired {
    background: url(../images/wired.svg)
}
.icon.wireless {
    background: url(../images/wireless.svg)
}
.icon.client {
    background: url(../images/client.svg)
}
.icon.troubleshoot {
    background: url(../images/troubleshoot.svg)
}
.icon.firmware{
    background: url(../images/firmware.png);
}
.icon.report{
    background: url(../images/report.png);
}