@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/JTUQjIg1_i6t8kCHKm459WxRxC7mw9c.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/JTUQjIg1_i6t8kCHKm459WxRzS7mw9c.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/JTUQjIg1_i6t8kCHKm459WxRxi7mw9c.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/JTUQjIg1_i6t8kCHKm459WxRxy7mw9c.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url(../fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.loader-container {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}

.loader::before,
.loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #0278E7;
    animation: prixClipFix 2s linear infinite;
}

.loader::after {
    inset: 8px;
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: #28ACE2;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }

    75%,
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
}

/*=================COMMON=================*/
body {
    margin: 0px;
    padding: 0px;
    font-size: 16px;
    font-family: 'Montserrat';
}

img {
    max-width: 100%;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
}

input:focus-visible,
select:focus-visible {
    outline: none;
}

.modal-footer button {
    height: 40px;
    min-width: 100px;
    padding: 0px 15px;
}

form label {
    color: #9699A8;
}

form .form-control {
    height: 40px;
}

form select {
    height: 40px;
    border: solid 1px #D5D8DB;
    border-radius: 0.375rem;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    appearance: none;
    background: url(../images/down.svg) right 10px center no-repeat;
    background-size: 15px;
}

form .row {
    margin-bottom: 5px;
}

input:focus,
select:focus,
textarea:focus,
.form-control:focus {
    box-shadow: none !important;
    /* border:solid 1px #D5D8DB !important; */
}

.modal-title {
    font-size: 20px;
}

.mb-4 {
    margin-bottom: 20px !important;
}
a.link{
    color:#0278e7;
    text-decoration: none;
    font-weight: 500;
}
/*================= Checkbox styles =================*/
label.checkbox {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: -1px;
    width: 18px;
    height: 18px;
}

label.checkbox input[type=checkbox] {
    position: absolute;
    opacity: 0;
}

label.checkbox input[type=checkbox]:focus~span {
    border: 2px solid #cbe6f1;
}

label.checkbox span {
    border-radius: 3px;
    position: absolute;
    left: 0;
    width: 18px;
    height: 18px;
    background-color: transparent;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/*=================SCROLLBAR=================*/
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
    border-radius: 3px;
}
.port-box::-webkit-scrollbar {
    height: 5px;
}
/*=================HEADING=================*/
h1 {
    font-size: 35px;
    font-weight: 800;
}

h2 {}

h3 {}

h4 {}


.inc-form {
    margin-bottom: 5px;
}

.inc-form label {
    color: #8C8D8D;
    display: block;
    margin-bottom: 3px;
}

.inc-form input,
.inc-form select {
    border: solid 1px #D5D8DB;
    height: 50px;
    border-radius: 5px;
    padding: 10px 15px;
    width: 100%;
}

.inc-form input:focus,
.inc-form select:focus {
    outline: 0px;
    box-shadow: none !important;
}

.pd0 {
    padding: 0px !important;
}

/* ==========OVERVIEW========= */
.inc-overview {
    display: flex;
    gap: 20px;
}

.inc-col-1 {
    flex: 1;
}

.inc-col-1 .first-half {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.inc-col-1 .second-half {
    display: flex;
    gap: 20px;
}

.edge-card,
.device-card,
.fault-box {
    flex: 0 0 auto;
    width: 27.520661157%;
}

.con-client {
    flex: 1;
}

.trend-card {
    flex: 1;
}

.inc-col-2 {
    flex: 0 0 auto;
    width: 24.0740740741%;
}

.inc-o-col-1 {
    flex: 0 0 auto;
    width: 20.5555555556%;
}

.inc-o-col-2 {
    flex: 0 0 auto;
    width: 53.2098765432%;
}

.inc-o-col-2 .inc-fault-client {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.inc-o-col-2 .inc-fault-client .fault-box {
    flex: 0 0 auto;
    width: 38.6310904872%;
}

.inc-o-col-2 .inc-fault-client .con-client {
    flex: 1;
}

.inc-o-col-3 {
    flex: 0 0 auto;
    width: 24.0740740741%;
}

.edge-chart {
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.edge-chart .ant-progress-outer {
    border: solid 4px #F7F7F7;
    border-radius: 5px;
}

.edge-chart .ant-progress-inner {
    border-radius: 3px;
    background-color: #DADADA;
    height: 30px;
    position: relative;
}

.edge-chart .ant-progress-inner div {
    height: 30px !important;
    border-radius: 5px;
    background-color: rgb(38, 191, 120);
}

.edge-legend {
    font-size: 14px;
    gap: 15px;
    position: absolute;
    bottom: 10px;
}

.edge-legend ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.edge-legend ul li {
    margin: 2px 0px;
    flex: 50% 50% 1;
    cursor: pointer;
}

.edge-legend ul li span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #CCCCCC;
    margin-right: 10px;
}

.edge-legend strong {
    display: inline-block;
    margin-left: 10px;
}

.edge-legend span.active {
    background-color: rgb(38, 191, 120);
}

.edge-legend span.managed {
    background-color: rgb(235, 137, 25);
}

/*=================CARD=================*/
.inc-card {
    border-radius: 10px;
    box-shadow: 0px 3px 0px rgba(149, 149, 149, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.inc-card .inc-card-body {
    flex: 1;
}

.inc-card.audit-table {
    height: 475px;
}

.inc-card-title {
    height: 56px;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 1px 0px rgba(178, 178, 178, 0.1);
    padding: 0px 20px 0px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-title-dropdown {
    display: flex;
    gap: 21px;
}

.card-title-dropdown div:first-child::before {
    content: '';
    position: absolute;
    right: -12px;
    top: 2px;
    height: 15px;
    width: 1px;
    background-color: #CCC;
}

.card-count {
    font-weight: 600;
    font-size: 18px;
}

.inc-card-title h3 {
    font-weight: 600;
    font-size: 16px;
    margin: 0px;
    position: relative;
    line-height: 20px;
}

.inc-card-title h3::before {
    content: '';
    position: absolute;
    top: 0px;
    left: -7px;
    height: 20px;
    width: 3px;
    background: #C2ADEF;
    border-radius: 2px;
}

.inc-card-title h3::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: -7px;
    height: 10px;
    width: 3px;
    background: #91DBF2;
    border-radius: 2px;
}

.inc-card-title h3 span {
    margin-left: 10px;
    cursor: pointer;
}

.inc-card-body {
    padding: 20px;
}

.inc-card.ch-100 {
    min-height: calc(100vh - 115px);
}
.inc-card.h-auto{
    height: auto;
}

.inc-card-footer {
    position: absolute;
    bottom: 15px;
    left: 20px;
    right: 20px;
    padding-top: 15px;
    display: flex;
    gap: 10px;
}

.inc-card-footer button {
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.full-card {
    height: calc(100vh - 107px);
}

.inc-card h4 {
    font-size: 16px;
    font-weight: 600;
}

.inc-card-header {
    display: flex;
    justify-content: space-between;
    padding: 2px 0px 15px 0px;
    align-items: center;
    min-height: 42px;
    margin-bottom: 5px;
}

.inc-card-header .inc-card-title {
    font-weight: 600;
}

.inc-card-title .inc-card-button {
    display: flex;
    gap: 0.5rem;
}

.inc-card-header .tag {
    font-size: 12px;
}

.inc-card-header .tag-btn {
    background: #28ACE2;
    color: #FFFFFF;
    cursor: pointer;
}

.icon-btn {
    border: 0px;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    font-size: 14px;
}
.icon-btn:disabled {
   cursor:not-allowed;
}

.text-btn {
    height: 1.75rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    padding: 0px 10px;
    line-height: 1.75rem;
    font-weight: 400;
    transition: 0.2s;
    font-size: 14px;
}

.text-btn i {
    margin-right: 5px;
}

.text-icon-btn i {
    margin: 0px;
    background-size: 13px !important;
}

.icon-btn i {
    margin: 0px;
    background-size: 13px !important;
}

/*=================BUTTON=================*/
button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn {
    border: 0px;
    border-radius: 5px;
}

.ibtn {
    border: 0px;
    border-radius: 8px;
}

.btn-primary {
    font-size: 18px;
}

.btn-login {
    background: #1976D3 !important;
    color: #FFFFFF !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
    transition: 0.2s;
}

.btn-login:disabled {
    cursor: not-allowed;
    pointer-events: all;
    background: #e6e6e6 !important;
    color: #808a96 !important;
}

.mac-button {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.mac-button .btn {
    height: 35px;
    font-size: 14px;
}

.mac-button .btn.primary-btn {
    background: #1976D3 !important;
    color: #FFFFFF;
}

/*=================LOGIN=================*/
.inc-login {
    display: flex;
    min-height: 932px;
    height: 100vh;
    background: #FFFFFF;
    background: #FFFFFF url(../images/login-bg.png) center right no-repeat;
    background-size: contain;
}

.login-form {
    flex: 1;
}

.inc-login .login-banner {
    flex: 1;
    color: #BADCFF;
}

.inc-login .login-banner div {
    position: absolute;
    right: 100px;
    max-width: 600px;
    top: 130px;
}

.inc-login .login-banner h3 {
    font-weight: 600;
    color: #FFFFFF;
}

.login-form-container {
    width: 420px;
}

.login-form-container.userprofile {
    max-width: 900px;
    width: 100%;
    background-color: #FFFFFF;
    padding: 30px;
    padding-bottom: 20px;
    border: solid 1px #CCCCCC;
    border-radius: 30px;
}

.login-form-container.userprofile .inc-logo {
    width: 250px;
}

.login-form-container.userprofile h1 {
    margin-top: 10px;
}

.login-form-container.userprofile .user-footer {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    justify-content: end;
    border-top: solid 1px #ebebeb;
    padding-top: 15px;
}

.login-form-container.userprofile .user-footer button {
    height: 40px;
    width: 100px;
    margin: 0px;
}

.login-form-container .line-textbox {
    position: relative;
}

.login-form-container input {
    background: #FFFFFF;
    color: #333333;
    font-family: 'Montserrat';
    padding-right: 56px;
}



.login-form-container input:-webkit-autofill,
.login-form-container input:-webkit-autofill:hover,
.login-form-container input:-webkit-autofill:focus,
.login-form-container input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #333333 !important;
}

.login-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-text {
    margin-bottom: 30px;
}

.login-text h1 {
    font-size: 32px;
    font-weight: 800;
    margin-top: 40px;
    color: #212529;
}

.login-text p {
    font-size: 16px;
}

.login-form h1 p {
    font-size: 16px;
    font-weight: 400;
    color: #777777;
    margin-top: 5px;
    line-height: 24px;
}

.login-form .forgot-text {
    text-align: right;
}

.login-form .forgot-text a {
    color: #1976D3;
    text-decoration: none;
    transition: .2s;
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
}

.login-form .forgot-text a:hover {
    text-decoration: underline;
}

.login-form .btn {
    width: 100%;
    height: 50px;
    margin-top: 30px;
}

.login-logo {
    background: #FFFFFF url(../images/login-banner-2.png) bottom right no-repeat;
    background-size: 100% 100%;
    flex: 1;
    padding-top: 90px;
    padding-left: 90px;
}

.login-logo .inc-logo {
    height: 150px;
}

.login-logo span {
    font-size: 124px;
    color: #1976D3;
    font-weight: 800;
    position: relative;
    height: 185px;
}

.login-logo span::after {
    content: '';
    position: absolute;
    bottom: 30px;
    right: -42px;
    width: 30px;
    height: 30px;
    background: #1976D3;
    border-radius: 15px;
}

.login-logo strong img {
    height: 130px;
}

.login-logo div {
    margin-top: 150px;
    color: #000000;
}

.login-logo div h1 {
    font-weight: 700;
    font-size: 62px;
}

.login-logo div p {
    font-size: 27px;
}

.show-password {
    position: absolute;
    right: 12px;
    bottom: 15px;
    opacity: 0.7;
}

.social-login .s-text {
    text-align: center;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

.social-login .s-text::before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #DDDDDD;
    top: 10px;
    left: 0px;
    width: 100%;
    z-index: 1;
}

.social-login .s-text span {
    position: relative;
    z-index: 2;
    padding: 0px 10px;
    background: #FFFFFF;
    font-weight: 500;
    color: #333333;
}

.social-login .s-button {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.social-login .s-button button {
    border: solid 1px #D5D8DB;
    border-radius: 8px;
    background: #FFFFFF;
    width: 150px;
    height: 50px;
    color: #333333;
}

.social-login .s-button button img {
    height: 30px;
    margin-right: 15px;
}

.back-login {
    cursor: pointer;

}

.back-login:hover {
    color: #0d6efd !important;
}

/*=================HEADER=================*/
#top-bar {
    margin-left: 255px;
    height: 59px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: calc(100% - 255px);
    top: 0px;
    z-index: 99;
}
.copyright-text{
    font-size: 14px;
    text-align: center;
    margin-bottom: -15px;
    margin-top: 8px;
}
.header-title {
    font-size: 16px;
    margin: 0px;
    font-weight: 600;
}

.header-title p {
    font-size: 14px;
    color: #8B8B8B;
    margin: 0px !important;
    font-weight: 400;
    line-height: 14px;
}

.header-title p a {
    text-decoration: none;
}

.header-control {
    display: flex;
    align-items: center;
    gap: 10px;
}
.header-control .switch-box{
    font-size: 12px;
}
.name-post {
    text-align: right;
}

.name-post strong {
    font-weight: 600;
    font-size: 14px;
}

.name-post p {
    margin: 0px !important;
    line-height: 14px;
    font-size: 14px;
}

.header-notification {
    display: flex;
    align-items: center;
}

.header-notification .icon {
    height: 22px !important;
    background-size: 16px !important;
}

.header-profile-user {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-profile-user .profile-user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 20px;
    background: #EEF8FD;
    border: solid 1px #28ACE2;
    color: #28ACE2;
    font-weight: 600;

}

.header-profile-user .profile-user.dark {
    background: #28ACE2;
    border: solid 1px #28ACE2;
    color: #FFFFFF;
}

.header-profile-user .profile-user.profile-icon i {
    margin: 0px;
    filter: invert(53%) sepia(97%) saturate(657%) hue-rotate(164deg) brightness(93%) contrast(90%);
}

.header-profile-user .profile-user.profile-img img {
    width: 100%;
    border-radius: 20px;
}

/* .breadcrumb{
    position: absolute;
    z-index: 999;
    top: 27px;
    font-size: 14px;
}
.breadcrumb ol{
    list-style: none;
    margin: 0px;
    padding: 0px;
} */
/*=================NAVIGATION=================*/
.navbar-menu {
    width: 255px;
    z-index: 1002;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    box-shadow: 0px 3px 6px rgba(183, 192, 206, 0.33);
}

.navbar-brand-box {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 20px 10px;
    max-height: 130px;
}

.navbar-brand-box img {
    width: 250px;
}

.navbar-brand-box span {
    color: #0278E7;
    font-size: 47px;
    font-weight: 800;
    position: relative;
}

.navbar-brand-box span::after {
    content: '';
    position: absolute;
    bottom: 18px;
    right: -15px;
    width: 12px;
    height: 12px;
    background: #0278E7;
    border-radius: 6px;
}

.simplebar-scrollable-y {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 130px);
}

/* .inc-navigation{
    font-size: 14px;    
} */
.inc-navigation ul {
    margin: 0px;
    padding: 0px;
}

.inc-navigation a {
    font-weight: 500;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    border-left: solid 5px transparent;
    transition: 0.3s;
    height: 45px;
}

.inc-navigation button {
    font-weight: 500;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    transition: 0.3s;
    height: 55px;
    width: 100%;
    background: transparent;
    border: 0px;
    border-left: solid 5px transparent;
}

.inc-navigation button i.fa {
    position: absolute;
    font-size: 18px;
    right: 15px;
}
.inc-navigation li i{
    margin-right: 0px;
}

/*=================MAIN BODY=================*/
.body-container {
    margin-left: 255px;
    padding: 20px;
    margin-top: 59px;
    min-height: calc(100vh - 59px);
}





/*=================DASHBOARD=================*/
/*--DEVICE PERFORMANCE--*/
.device-performance {
    display: flex;
}

.d-cpu,
.d-ram,
.d-device,
.d-db {
    flex: 1;
    border-left: solid 1px #F1F1F1;
    padding: 20px;
}

.dp-chart {
    background: url(../images/d-chart-gray.jpg) top center no-repeat;
    background-size: 95%;
    min-height: 183px;
}

.dp-chart img {
    max-width: 100%;
}

.dp-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.dp-title span {
    background: #F2F7FF;
    display: flex;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.dp-title span img {
    width: 25px;
}

.dp-title h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
}

.recharts-wrapper {
    margin: auto;
    z-index: 10;
}

/*--TASK--*/
.task-icon {
    display: flex;
    width: 45px;
    height: 45px;
    background: #f4f4f4;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 20px;
}

.task-icon img {
    filter: invert(80%) sepia(12%) saturate(0%) hue-rotate(30deg) brightness(100%) contrast(92%);
}

.task-icon.pie {
    background: #FF6B6B;
}

.task-icon.fire {
    background: #FFAD01;
}

.task-icon.flag {
    background: #26BF78;
}

.task-icon.line {
    background: #118AB2;
}

.task-icon.pie img {
    filter: invert(25%) sepia(42%) saturate(1801%) hue-rotate(222deg) brightness(97%) contrast(87%);
}

.task-icon.fire img {
    filter: invert(42%) sepia(98%) saturate(363%) hue-rotate(93deg) brightness(91%) contrast(88%);
}

.task-icon.flag img {
    filter: invert(39%) sepia(73%) saturate(874%) hue-rotate(194deg) brightness(90%) contrast(97%);
}

.task-icon.line img {
    filter: invert(55%) sepia(65%) saturate(404%) hue-rotate(324deg) brightness(101%) contrast(95%);
}

.task-type {
    font-size: 14px;
    color: #8B8B8B;
    margin: 0px 0px 5px 0px;
}

.task-count h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0px;
    line-height: 28px;
    margin-bottom: 20px;
}

.task-count h2[disabled] {
    color: #cccccc;
}

.task-count span {
    font-size: 14px;
    color: #8B8B8B;
}

.task-count a {
    text-decoration: none;
    color: #ED3237;
}

.task-count span.txt-red {
    color: #FF0000;
}

.task-count span.txt-green {
    color: #7AC142;
}

.alarm-box {
    display: flex;
    gap: 15px;
}

.alarm-box div {
    flex: 1;
}

.alarm-box .fa-line-chart {
    color: #999999;
    font-size: 30px;
    justify-content: end;
}

/*-----------*/
.task-performed {
    background: #f4f4f4;
    margin-top: 15px;
    border-left: solid 5px #CCCCCC;
    padding: 10px 15px 5px 15px;
}

.task-performed h3 {
    font-size: 24px;
    font-weight: 700;
    margin: 0px;
    line-height: 20px;
}

.task-performed h3[disabled] {
    color: #ababab;
}

.task-performed span {
    font-size: 14px;
    color: #8B8B8B;
}

.task-performed.red {
    background: #FFEFEF;
    border-left: solid 5px #FF3B3B;
}

.task-performed.yellow {
    background: #f9f7ef;
    border-left: solid 5px #FEC400;
}

.task-performed.green {
    background: #E6FFEC;
    border-left: solid 5px #7AC142;
}

/*---DEVICE GRAPH---*/
.device-graph {
    background: url(../images/device-graph-gray.jpg) center center no-repeat;
    height: 260px;
    background-size: 99%;
}

/*--Dashboard Pie CHART---*/
.d-ring-chart {
    text-align: center;
    margin-top: 20px;
    padding: 10px 0px;
}

.success-disable {
    max-width: 238px;
    width: 100%;
    height: 238px;
    margin: 50px auto;
    background: url(../images/ring-chart-gray.png) center center no-repeat;
    background-size: cover;
}

.d-chart-label {
    margin-top: 20px;
    text-align: center;
}

.d-chart-label ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.d-chart-label ul li {
    display: flex;
    justify-content: space-between;
    padding: 2px 3px;
    align-items: center;
}

.d-chart-label span {
    color: #8B8B8B;
    display: inline-flex;
    align-items: center;
}

.d-chart-label ul li strong {
    font-weight: 600;
}

.d-chart-label span i {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background: #f4f4f4;
    margin-right: 10px;
}

.d-chart-label span i.bl {
    background: #1F78B4;
}

.d-chart-label span i.dbl {
    background: #00529B;
}

.d-chart-label span i.dgr {
    background: #377B2B;
}

.d-chart-label span i.gray {
    background: #e8e8e8;
}

.d-chart-label span i.online {
    background: #7AC142;
}

.d-chart-label span i.offline {
    background: #FF3B3B;
}

.d-chart-label span i.total {
    background: #000000;
}



/*=================TABLE=================*/

.list-search {
    height: 30px;
    /* border: solid 1px #C3E1FE;
    background: #EEF8FD url(../images/search.svg) 5px 5px no-repeat; */
    background-size: 20px;
    border-radius: 4px;
    width: 250px;
    display: block;
    padding: 0px 10px 0px 30px;
    font-size: 14px;
}

.inc-card-table {
    overflow: auto;
    min-height: 100px;
    max-height: calc(100vh - 270px);
}

.inc-card-table.half {
    height: 330px;
}

.inc-card-table table {
    width: 100%;
    font-size: 14px;
}

/* .inc-card-table table thead th::before{
    content: '';
    position: absolute;
    bottom:0px;
    left: -5px;
    right: -5px;
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
} */
.inc-card-table table thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    font-weight: 500;
}

.inc-card-table table thead th i {
    margin-left: 5px;
    font-size: 12px;
    cursor: pointer;
}

.inc-card-table table thead th {
    padding: 5px 5px 3px 5px;
}

.m-head thead th {
    padding-bottom: 10px !important;
}

.inc-card-table table tbody tr td:first-child {
    border-radius: 5px 0px 0px 5px;
}

.inc-card-table table tbody tr td:last-child {
    border-radius: 0px 5px 5px 0px;
}

.inc-card-table table tbody td {
    padding: 10px 5px;
}

.inc-card-table table.full-table thead tr th:first-child,
.inc-card-table table.full-table tbody tr td:first-child {
    padding-left: 15px;
    width: 20px;
}
.action150 thead th:last-child{
    width: 150px;
}
.inc-card-table table thead tr th:first-child,
.inc-card-table table tbody tr td:first-child {
    width: auto;
    padding-left: 15px;
}

.inc-card-table table tbody td .d-status {
    /* background: #f4f4f4; */
    border-radius: 6px;
    font-size: 12px;
    padding: 5px 15px;
}

.inc-card-table table tbody td .d-status.online {
    color: #7AC142;
}

.inc-card-table table tbody td .d-status.offline {
    color: #FF3B3B;
}
.inc-card-table table tbody td .d-status.awaitingConnection {
    color: #e58a02;
}

td.rssi-icon img{
    width: 20px;
    margin-left: 7px;
}

.tags>.tag {
    margin-right: 5px;
}

span.tag {
    background: #E6E6E6;
    border-radius: 10px;
    display: inline-block;
    padding: 0px 10px;
    color: #5A5D5A;
}

.pagination-info {
    margin-top: 10px;
}

table+.error {
    text-align: center;
    padding: 50px 0px;
    color: #FF0000;
}

/*=================DEVICE DETAIL=================*/
.device-info-wrap {
    display: grid;
    grid-template-columns: 33.33333333% 1fr;
    grid-gap: 20px;
}
.device-info-wrap .info-card-4 .device-info {
    font-size: 14px;
}
.device-info div {
    display: flex;
    margin: 7px 0px;
}

.device-info div strong {
    flex: 1;
    font-weight: 500;
}

.device-info div span {
    flex: 1;
}

.inc-card-table table tbody tr th {
    padding: 10px 10px;
}

.parameter-table tbody tr td:nth-child(2) {
    text-align: right;
}

.parameter-table tbody tr td:last-child {
    width: 20px;
}

.inc-card-table.audit-table {
    height: 38.95vh;
}

.device-info0 {
    text-decoration: underline;
    cursor: pointer;
}
.port-cable{
    width: 50px;
    position: absolute;
    z-index: 1;
    left: -30px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
}
.port-legend {
    display: flex;
    align-items: center;
}
.port-legend ul{
    list-style: none;
}
.port-legend ul li{
    line-height: 28px;
}
.port-legend ul li i{
    font-size: 14px;
    margin-right: 5px;
}
.port-legend ul li.ap i{
    color: #33a02c;
}
.port-legend ul li.anp i{
    color: #1f78b4;
}
.port-legend ul li.ep i{
    color: #fb9a99;
}
.port-legend ul li.dp i{
    color: #8884d8;
}
.port-legend ul li.fp i{
    color: #ffad01;
}

/*=================INC SLIDER=================*/
.inc-slider {
    width: 900px;
    position: fixed;
    z-index: 99;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.slider-title {
    background: #0278E7;
    color: #FFFFFF;
    padding: 20px;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
}

.slider-title h4 {
    margin: 0px;
}

.slider-title img {
    width: 20px;
    -webkit-filter: invert(100%);
    /* Safari/Chrome */
    filter: invert(100%);
    cursor: pointer;
}

.slider-body {
    padding: 20px 25px;
    height: calc(100vh - 168px);
    overflow: auto;
    flex: 1;
}

.slider-footer {
    padding: 20px 25px;
    border-top: solid 1px #E3E3EB;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.slider-footer button {
    width: 100px;
    height: 40px;
}

.top-header .pagename {
    font-size: 20px;
    font-weight: 700;
}

.modal.customModal.show {
    background: rgba(0, 0, 0, 0.5);
}

.customModal {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.c-modal-dialog {
    transition: transform .3s ease-out;
    margin: 1.75rem auto;
    position: relative;
    pointer-events: none;
    max-width: 500px;
    display: flex;
    align-items: center;

}
.c-modal-dialog.small{
    max-width: 550px;
}
.modal-dialog.small{
    max-width: 550px;
}
.modal-dialog.medium,
.c-modal-dialog.medium{
    max-width: 750px;
}
.c-modal-dialog.w600 {
    max-width: 600px;
}

.customModal .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
}
.customModal .modal-dialog{
    margin: auto;
}
.c-modal-dialog .modal-content {
    border-radius: .3rem;
}

.c-modal-dialog .modal-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.c-modal-dialog .modal-body {
    padding: 20px;
}

.c-modal-dialog .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
}

.c-modal-dialog .modal-footer>* {
    margin: .25rem;
}
.confirm-modal img{
    width: 60px !important;
}

.confirm-modal .modal-body p{
    font-size: 16px;
    line-height: 28px;
    margin: 20px 30px 20px 30px;
}
.modal-body h3{
    margin: 0px 0px 25px 0px;
    font-size: 18px;
    color: #FF0000;
}
.confirm-modal .modal-footer{
    justify-content: center;
    gap: 15px;
    border-top: 0px;
}
.confirm-modal .modal-footer button{
    margin: inherit;
}
.sublabel {
    border-radius: 8px;
    padding: 5px;
    padding-bottom: 20px;
    margin: 0px;
}

.sublabel strong {
    font-weight: 500;
}

.sublabel span {
    display: block;
    font-size: 14px;
}

/*INC Modal Alert*/
.inc-alert .modal-dialog {
    width: 420px;
}

.inc-alert .modal-dialog {
    width: 500px;
}

.inc-alert .modal-dialog .modal-content {
    padding: 20px 30px;
    border-radius: 16px;
    text-align: center;
}

.inc-alert .modal-dialog .modal-content h3 {
    font-weight: 700;
    font-size: 34px;
    margin-top: 20px;
}

.inc-alert .modal-dialog .modal-content .success-icon {
    background: #bdeb98;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 2px #7AC142;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.inc-alert .modal-dialog .modal-content .success-icon i {
    margin: 0px;
    background-size: 30px !important;
    filter: invert(68%) sepia(68%) saturate(407%) hue-rotate(49deg) brightness(70%) contrast(88%);
}

.inc-alert .modal-dialog .modal-content p {
    margin: 10px 0px 25px 0px;
}

.inc-alert .modal-dialog .modal-content button {
    width: 150px;
    font-size: 16px;
    height: 40px;
    margin: auto;
}

.confirm-btn button {
    margin: 0px 5px !important;
    width: 120px !important;
}




/* ====================DASHBOARD=============== */
/*Device Info*/
.device-count {
    display: flex;
}

.device-count div {
    flex: 1;
    text-align: center;
    padding: 30px 0px;
}

.device-count div strong {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 18px;
}

.device-count div span {
    display: inline-block;
    height: 30px;
    width: 75px;
    border-radius: 20px;
    background: #CCCCCC;
    margin-top: 5px;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    line-height: 30px;
}

.device-count div span.green-pills {
    background: #26BF78;
}

.device-count div span.red-pills {
    background: #ED3237;
}

.device-count div span.orange-pills {
    background: #eb8919;
}


/*Fault Card*/
.fault-legends {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    width: 100%;
    position: absolute;
    bottom: 13px;
}

.fault-legends div {
    flex: 1;
    text-align: center;
    position: relative;
    flex: 1 1 auto;
    cursor: pointer;
}

.fault-legends div::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 40px;
    top: 4px;
    left: 0px;
}

.fault-legends div:first-child::before {
    display: none;
}

.fault-legends div strong {
    font-size: 18px;
    color: #333333;
    display: block;
    font-weight: 600;
}

.fault-legends div span {
    font-size: 14px;
    color: #93999E;
    margin: 0px;
    position: relative;
}

.fault-legends div:first-child span {
    color: #A5080D;
}

.fault-legends div:nth-child(2) span {
    color: #ED3237;
}

.fault-legends div:nth-child(3) span {
    color: #26BF78;
}

.fault-legends div span i {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #CCCCCC;
    border-radius: 5px;
    margin-right: 5px;
    position: absolute;
    top: 4px;
    left: -15px;
}

.fault-legends div span i.l-major {
    background: #ED3237;
}

.fault-legends div span i.l-minor {
    background: #FFD23E;
}

.fault-legends div span i.l-normal {
    background: #26BF78;
}

/*Card Dropdown*/
.inc-card-title span.dropdown {
    color: #8C9097;
    font-size: 14px;
}

.inc-card-title span.dropdown span {
    cursor: pointer;
}

.dropdown-menu {
    color: #8C9097 !important;
    font-size: 14px;
}

/*Onboarding Trends*/
.trends-legends {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    position: absolute;
    width: 100%;
    bottom: 14px;
}

.trends-legends ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    gap: 20px;
    font-size: 14px;
}

.trends-legends ul li span {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #CCCCCC;
    border-radius: 5px;
    margin-right: 6px;
}

.trends-legends ul li span.d1 {
    background: #33A02C;
}

.trends-legends ul li span.d2 {
    background: #1F78B4;
}

.trends-legends ul li span.d3 {
    background: #FB9A99;
}

.trends-legends ul li span.d4 {
    background: #f1a92d;
}

.trends-legends ul li span.d5 {
    background: #A6CEE3;
}


/* Client Legend */
.client-legend {
    border-radius: 15px;
    background: #EEF8FD;
    padding: 15px 20px;
    margin: 5px 10px;
}

.client-legend ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    gap: 30px;
    color: #5E6E8D;
    font-size: 14px;
}

.client-legend ul li span {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #CCCCCC;
    border-radius: 5px;
    margin-right: 6px;
}

.client-legend ul li span.d1 {
    background: #A932ED;
}

.client-legend ul li span.d2 {
    background: #08B4A9;
}

.client-legend ul li span.d3 {
    background: #98C75B;
}

.client-legend ul li span.d4 {
    background: #2892E3;
}

.client-legend ul li span.d5 {
    background: #FFC000;
}


/* =========SYSTEM HEALTH */
.inc-sys-health {
    display: flex;
    padding: 0px 20px;
    margin-bottom: 30px;
    align-items: center;
}

.inc-sys-health div:first-child {
    margin-right: 10px;
}

.inc-sys-health div:last-child {
    flex: 1;
    margin-top: 5px;
}

.sys-info {
    display: flex;
    justify-content: space-between;
}

.inc-progress {
    height: 10px;
    border-radius: 5px;
    background: #F4F4F4;
    position: relative;
}

.inc-progress span {
    display: inline-block;
    height: 10px;
    width: 10%;
    border-radius: 5px;
    background: #CCCCCC;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}

.grafna-link {
    position: relative;
}

.grafna-link a {
    font-weight: 500;
}

.grafna-link .popup {
    position: absolute;
    z-index: 2;
    background: #EEF8FD;
    border: solid 1px #28ACE2;
    border-radius: 10px;
    width: 250px;
    right: -5px;
    padding: 10px 15px;
    font-size: 14px;
}

.grafna-link .popup p {
    margin-bottom: 5px;
}

.grafna-link .popup p:last-child {
    margin-bottom: 0px;
}

.health-chart {
    height: 200px;
}

.inc-progress.cpu {
    background: #FFF7E1;
}

.inc-progress.cpu span {
    background: #f0cb5e;
}

.inc-progress.memory {
    background: #f1fbc1;
}

.inc-progress.memory span {
    background: #a2bf2e;
}

.inc-progress.disk {
    background: #DBE3F2;
}

.inc-progress.disk span {
    background: #264FBF;
}

.inc-progress.db {
    background: #E7DBF2;
}

.inc-progress.db span {
    background: #661F68;
}


/* DR STAtUS */
.dr-status {
    border-radius: 15px;
    padding: 15px 20px;
    margin: 20px 0px 23px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.dr-status div:first-child strong {
    display: block;
}

.dr-status div:first-child span {
    color: #5E6E8D;
}

/* NODE */
.node-sec {
    margin-top: 53px;
}

.node-chart {
    padding: 0px 20px;
}

.node-chart div {
    text-align: center;
    padding: 45px 20px;
}

.node-legends {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.node-legends ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    gap: 10px;
    font-size: 14px;
    flex-direction: column;
}

.node-legends ul li span {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #CCCCCC;
    border-radius: 5px;
    margin-right: 6px;
}

.node-legends ul li span.d1 {
    background: #46CC6B;
}

.node-legends ul li span.d2 {
    background: #ED3237;
}

td .button-group,
.schedule-button {
    display: flex;
    align-items: center;
    gap: 5px;
}

td .button-group button,
td .button-group button:hover,
.schedule-button button,
.schedule-button button:hover {
    border: 0px;
    background: transparent;
    font-size: 14px;
    height: 23px;
}

td .button-group .text-btn:hover {
    color: #FFFFFF;
}

td .searchBox {
    border: solid 1px #dceff7;
    border-radius: 3px;
    padding: 3px 10px;
    height: 22px;
    font-size: 12px;
    margin-left: 5px;
}

.search-border td {
    padding-bottom: 8px;
}

.smallWidth {
    width: 60%;
    /* Adjust the width as needed */
    margin-right: 5px;
}




.firmware-card {
    border: solid 1px #CCCCCC;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 0px rgba(149, 149, 149, 0.1);
}

.f-card-title {
    font-weight: 600;
    font-size: 14px;
    color: #333;
    padding: 12px 20px;
    border-radius: 10px 10px 0px 0px;
    background: #f4f4f4;
    border-bottom: solid 1px #CCCCCC;
}

.f-card-body {
    padding: 20px;
}

.f-card-body p {
    margin: 0px;
}

.f-card-body .inc-card-table thead {
    padding: 10px;
}

.f-card-body .inc-card-table thead th {
    border-bottom: solid 1px #CCCCCC;
    padding-bottom: 10px;
    min-width: 200px;
}

.topologyLink2 {
    fill: none;
    stroke: #ccc;
    stroke-width: 10px;
    opacity: 0;
    cursor: pointer;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
    /* Rounded slider */
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
    /* Rounded slider */
}

input:checked+.slider {
    background-color: #48a85b;
}

input:focus+.slider {
    box-shadow: 0 0 1px #48a85b;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Additional styling */
.firmware-card {
    margin-bottom: 20px;
    /* Adds space between cards */
}

.f-card-body p {
    margin-bottom: 10px;
    /* Adds space between paragraph and switch */
}

.switch {
    font-size: 14px;
    /* Increases font size */
}

.switch-container {
    display: flex;
    align-items: center;
}

.switch-label {
    margin-right: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #333;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #4a943c;
}

input:focus+.slider {
    box-shadow: 0 0 1px #4a943c;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.date-time-pickers {
    display: flex;
    flex-wrap: wrap;
}

.picker {
    margin-right: 20px;
    margin-bottom: 10px;
}

label {
    display: block;
    margin-bottom: 5px;
}


.filter-pop {
    position: relative;
}

.filter-pop .device-filter {
    position: absolute;
    z-index: 12;
    top: 40px;
    right: -50px;
}

.device-filter {
    width: 400px;
    border-radius: 5px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
}

.filter-title {
    padding: 13px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-title span {
    font-size: 15px;
    font-weight: 500;
}

.filter-title a {
    font-size: 12px;
    color: #067AE6;
    text-decoration: none;
    font-weight: 500;
}

.device-filter .filter-body {
    padding: 16px;
}

.filter-body .cal-input,
.filter-body select {
    width: 100%;
    border-radius: 5px;
    height: 40px;
    padding: 0px 10px;
}

.filter-body h4 {
    font-size: 16px;
    margin: 10px 0px;
}

.filter-body ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    font-size: 14px;
}

.filter-body ul li {
    margin: 5px 0px 15px 0px;
}

.filter-body .last-filter {
    display: flex;
    gap: 10px;
}

.device-filter .filter-button {
    padding: 0px 16px 16px 16px;
    display: flex;
    gap: 10px;
    justify-content: end;
}

.device-filter .filter-button button {
    width: 100px;
    height: 40px;
    border: 0px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
}

.date-time-pickers {
    display: flex;
}

.picker {
    margin-right: 60px;
}

.inc-card-button {
    display: flex;
    justify-content: flex-end;
}
.inc-card-button button.hide{
    display: none;
}
.inc-card-button button.show{
    display: block;
}
.picker {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.switch-label {
    margin-right: 10px;
}

input[type="date"],
input[type="time"] {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.inc-card-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ibtn {
    padding: 5px 10px;
    /* Increase padding to make buttons larger */
    font-size: 16px;
    /* Increase font size for better readability */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.ibtn.btn-outline {
    border: 1px solid #007bff;
    /* Blue outline */
    color: #007bff;
    /* Blue text */
}

.ibtn.btn-outline:hover {
    background-color: #007bff;
    color: #fff;
}

.ibtn.btn-fill {
    background-color: #007bff;
    /* Blue background */
    color: #fff;
    /* White text */
    border: none;
}

.ibtn.btn-fill:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
}



.system-info {
    border-radius: 20px;
    background: #F0F1F7;
    padding: 30px;
    display: flex;
    justify-content: space-between;
}

.white-bg {
    background: #FFFFFF;
}

.system-info .white-bg {
    padding: 50px;
    border-radius: 20px;
    box-sizing: border-box;
    flex: 1;
}

.system-info .s-column div {
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
}

.system-info .s-column div div {
    margin: 0px;
}

.system-info .s-column div strong {
    font-weight: normal;
    color: #656565;
}

.system-info .s-column div span {
    font-weight: 600;
}

.sysinfo_banner {
    text-align: center;
    margin-bottom: 30px;
    align-items: center;
}

.sys-health {
    gap: 8px;
    flex-direction: row !important;
}

.sys-health .health-icon {
    border-radius: 11px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.sys-health .health-icon.green {
    background: #dcf1d6;
    color: #27AA00;
}

.sys-health .health-icon.red {
    background: #ffefef;
    color: #FF3B3B;
}

.system-box {
    display: flex;
}

.system-box .system-detail {
    display: flex;
    gap: 20px;
    flex: 1;
    padding-right: 50px;
}

.system-box .system-detail .s-column {
    flex: 1;
}

.system-box .system-banner {
    border-left: solid 1px #e6e6e6;
    padding-left: 30px;
}

.system-box .c-progress {
    width: 250px;
    height: 11px;
    border-radius: 6px;
    background: #E3E3E3;
    margin: 5px 0px 0px 0px !important;
}

.system-box .c-progress span {
    display: block;
    height: 11px;
    border-radius: 6px;
}

.yellow {
    background: #FCC100;
}

.green {
    background: #27AA00;
}



.dashboard-device-info {
    display: flex;
    gap: 50px;
}
.dashboard-device-info .dashboard-device-icon{
    min-width: 200px;
}
.dashboard-device-info .dashboard-device-icon strong {
    display: block;
    text-align: center;
    margin-top: 20px;
}

.dashboard-device-info .dashboard-device-detail {
    flex: 1;
}

.dashboard-device-info .dashboard-device-detail .detail-list {
    display: grid;
    padding: 5px 0px;
    row-gap: 15px;
    column-gap: 15px;
    grid-template-columns: repeat(4, 1fr);
}

.dashboard-device-info .dashboard-device-detail .detail-list div {
    /* max-width: 25%; */
    /* width: 100%; */
}

.dashboard-device-info .dashboard-device-detail .detail-list span,
.dashboard-device-info .dashboard-device-detail .detail-list strong {
    display: block;
}

.dashboard-device-info .dashboard-device-detail .detail-list span {
    /* color: #A3A3A3; */
    font-size: 14px;
}

.dashboard-device-info .dashboard-device-detail .graph {
    background: #F9F9F9;
    padding: 10px 15px;
    border-radius: 10px;
}

.dashboard-device-info .dashboard-device-detail .graph strong {
    display: block;
    margin-bottom: -20px;
}

.data-label {
    font-size: 14px;
    color: #93999E;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
}

.data-label span i {
    margin-right: 10px;
    margin-bottom: 3px;
    display: inline-block;
    width: 20px;
}

.data-label span.download i {
    border-top: solid 2px #4791FF;
}

.data-label span.upload i {
    border-top: dashed 2px #FF9050;
}



.client-list {
    display: flex;
    background: #FAFAFA;
    padding: 10px 15px;
    border-radius: 10px;
    margin-top: 10px;
    gap: 15px;
    align-items: center;
}

.client-list:first-child {
    margin-top: 0px;
}

.client-list .client-icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #E3E3E3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.client-list .client-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    line-height: 18px;
}

.client-list .client-info span {
    color: #93999E;
}

.client-list .client-usage {
    display: flex;
    flex-direction: column;
    text-align: end;
    line-height: 18px;
}

.client-list .client-usage strong {
    color: #E8B410;
}

.client-list .client-usage span {
    color: #93999E;
}

.client-list .action {
    color: #93999E;
    font-size: 18px;
    flex-basis: 30px;
    text-align: center;
    cursor: pointer;
}



/*====================DISCOVERY DEVICE==================*/
.discover-form {
    width: 100%;
    max-width: 850px;
    margin: auto;
    margin-bottom: 50px;
}

.discover-form label {
    color: #9699A8;
}

.discover-form .col-lg-6 {
    margin-bottom: 25px;
}

.discover-form input[type=text],
.discover-form select {
    height: 50px;
    border-radius: 5px;
}

.discover-form .discover-button {
    display: flex;
    gap: 10px;
    justify-content: end;
}

.inc-modal {
    width: 650px;
    border-radius: 16px;
    border: none;
}

.loading-device {
    text-align: center;
    height: 180px;
    padding: 55px 0px;
}

.loading-device h3 {
    font-size: 22px;
}

.loading-device p {
    font-size: 16px;
    color: #666666;
}

.loading-device p span {
    color: #000000;
    font-weight: 500;
}

.success-msg,
.confirm-modal .modal-body {
    text-align: center;
    padding: 50px 0px;
}

.success-msg img {
    width: 80px;
}

.success-msg h2 {
    text-align: center;
    font-size: 34px;
    font-weight: 600;
    margin-top: 20px;
}
.success-msg p {
    font-size: 16px;
    line-height: 28px;
    margin: 10px 0px 30px 0px;
}

.success-msg p span {
    color: #000000;
    font-weight: 400;
}

.success-msg p strong {
    color: #000000;
    font-weight: 600;
    display: block;
}

.success-msg button {
    width: 180px;
    height: 50px;
    border-radius: 8px;
    font-size: 16px;
    margin: auto;
}

.success-msg switch {
    width: 100px;
    height: 50px;
    border-radius: 8px;
    font-size: 16px;
    margin: auto;
}


.create-usr-pop {
    min-width: 800px;
}

/* =========TAB====== */
.nav-underline {
    font-size: 16px;
    margin-top: 13px;
}

.nav-underline li button {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.nav-underline .nav-link.active {
    border-width: 3px;
    font-weight: 600;
}

.nav-underline:has(.um.active)+div+.role-button {
    display: none;
}

.nav-underline:has(.um.active)+.user-button+.role-button+.permission-button {
    display: none;
}

.nav-underline:has(.rm.active)+.user-button {
    display: none;
}

.nav-underline:has(.rm.active)+.user-button+.role-button+.permission-button {
    display: none;
}

.nav-underline:has(.pm.active)+.user-button {
    display: none;
}

.nav-underline:has(.pm.active)+.user-button+.role-button {
    display: none;
}

.access-type {
    display: flex;
    gap: 15px;
}

.access-type label {
    height: 45px;
    border: solid 1px #D5D8DB;
    flex: 1;
    padding: 10px;
    border-radius: 0.375rem;
}


.per-role-select {
    max-width: 350px;
    margin: 10px 0px;
}

.permission-button {
    display: flex;
    justify-content: end;
}

.permission-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    align-items: center;
}

.permission-list div {
    border: solid 1px #DFE5f5;
    border-radius: 8px;
    width: calc(24% - 5px);
    height: 50px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    cursor: pointer;
}

.permission-wrapper {
    display: flex;
    height: calc(100vh - 311px);
}

.permission-name {
    border-right: solid 1px #e5e5e5;
    padding: 20px 30px 20px 0px;
    width: 350px;
    overflow: auto;
}

.permission-name ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.permission-name ul li {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 52px;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0px 15px;
    cursor: pointer;
}

.permission-type {
    padding: 20px 0px 20px 50px;
    flex: 1;
}

.permission-type .fade:not(.show) {
    display: none;
}

.permission-type ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    align-items: center;
}

.permission-type ul li {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 52px;
    border-radius: 8px;
    padding: 0px 15px;
    cursor: pointer;
    width: calc(24% - 5px);
}

/* =====ROLE TAB===== */
#roleTab button {
    background: #E6E6E6;
    border-radius: 0px;
    font-size: 16px;
    color: #808A96;
    margin: 0px 1px;
}

.inc-role-table th:nth-child(2),
.inc-role-table td:nth-child(2) {
    width: 250px;
}

.inc-role-table th:last-child,
.inc-role-table td:last-child {
    width: 100px;
}

.role-table {
    margin-top: 20px;
}

.role-table th,
.role-table td {
    padding: 10px !important;
    height: 45px;
    vertical-align: middle;
    text-align: center;
}

.role-table th:first-child,
.role-table td:first-child {
    text-align: left;
}

.role-table td label.checkbox {
    margin: auto;
}

.file-check {
    display: flex;
}

.missing-field {
    border-color: red !important;
    /* Use !important to override Bootstrap styles */
}

.w200 {
    width: 200px;
}

.topology-draft {
    max-width: 1585px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.topology-draft ul {
    position: absolute;
    width: 100%;
    z-index: 4;
    height: 100%;
}

.topology-draft ul li,
.topology-draft ul li button {
    width: 100%;
    height: 100%;
}
.topology-info tr th:nth-child(2), 
.topology-info tr th:nth-child(3){
   width: 100px;
}

.map-tooltip{
    width: 180px;
    font-size: 14px;
    background-color: #FFFFFF;
}
.map-heading{
    margin-bottom: 5px;
    display: block;
}
.map-info{
    display: flex;
    flex-direction: column;
}
.map-info div{
    display: flex;
    line-height: 13px;
    font-size: 11px;
}
.map-info div strong{
    position: relative;
}
.map-info div strong::after{
    content: ':';
    margin-left: 5px;
    margin-right: 10px;
}
.map-info div label{
    flex: 1;
}

.card-top {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 26px; */
}

.card-top h3 {
    font-size: 20px;
}

.card-top span {
    display: flex;
    background: #f4f4f4;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.card-top span.a-red {
    background: #FCD7D8;
    color: #ED3237;
}

.card-top span.a-yellow {
    background: #FFF8E4;
    color: #F8C63E;
}

.card-top span.a-green {
    background: #D8FFEF;
    color: #26BF81;
}

.card-top span.a-blue {
    background: #D8F5FF;
    color: #118AB2;
}

.audit-card-count {
    display: flex;
}

.audit-card-count div {
    flex: 1;
}

.audit-card-count div span {
    display: inline-block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #EEEEEE;
    margin-right: 10px;
}

.audit-card-count div strong {
    margin-right: 5px;
}

.audit-card-count.aud-red span {
    background-color: #ED3237;
}

.audit-card-count.aud-green span {
    background-color: #26BF81;
}

.audit-card-count.aud-yellow span {
    background-color: #F8C63E;
}

.audit-card-count.aud-blue span {
    background-color: #118AB2;
}

.audit-card-count h2 {
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 28px;
}

.audit-card-count .red {
    color: #FF0000;
}

.audit-card-count .green {
    color: #67b42c;
    background-color: transparent;
}

.audit-chart {
    height: 232px;
}
.updown-chart .recharts-responsive-container{
    max-width: 1360px;
}
.underline {
    text-decoration: none;
    color: #0278E7;
}

.underline:hover {
    text-decoration: underline;
}

/* .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px; 
  }
  
  .pagination button {
    padding: 5px 10px;
    margin: 0 3px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination button:disabled {
    color: #ccc;
    cursor: not-allowed;
  } */
.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    width: 160px;
    /* Adjust the width as needed */
    max-height: 160px;
    /* Adjust the max-height as needed */
    overflow-y: auto;
    /* Add scrollbar if content exceeds max-height */
}

.dropdown.open .dropdown-menu {
    display: block;
}

.inc-navigation ul {
    position: relative;
}

.inc-navigation ul .dropdown.open+li {
    position: relative;
    top: 0;
}

.inc-navigation .dropdown-menu {
    display: none;
    top: 100%;
    left: 5px;
    padding-left: 30px;
    z-index: 1000;
    width: 250px;
    /* Adjust the width as needed */
    max-height: 250px;
    /* Adjust the max-height as needed */
    overflow-y: auto;
    /* Add scrollbar if content exceeds max-height */
    box-shadow: none;
    border: 0px;
    border-radius: 0px;
    margin: 5px 0px;
}

/* .inc-navigation .dropdown-menu li{
    border-left: solid 2px #2A72E0;
  } */
.inc-navigation .dropdown-menu li a {
    height: 42px;
}

/* .inc-navigation .dropdown-menu li a:hover,
  .inc-navigation .dropdown-menu li a.active{
    background: transparent;
    color: #3661EB;
    border-color: transparent !important;
  } */

/* .inc-navigation .dropdown-menu li::before{
    content: '';
    position: absolute;
    z-index: 2;
    margin-left: -7px;
    margin-top: 15px;
    width: 12px;
    height: 12px;
    background: #003684;
    border-radius: 50%;
  } */
.not-found-container {
    text-align: center;
    /* Center align the content */
}

.not-found-title {
    color: red;
    /* Make the title text red */
    margin-top: 25%;
}

.ota-graphics {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.ota-card {
    border-radius: 15px;
    padding: 0px 20px;
    height: 80px;
    display: flex;
    align-items: center;
    line-height: 25px;
    gap: 20px;
    flex: 1;
}

.ota-graphics .dtype-count div {
    flex: 1;
    text-align: center;
}

.ota-graphics .dtype-count div strong {
    font-size: 18px;
    display: block;
}

.ota-graphics .dtype-count div span {
    background: #f5f5f5;
    display: inline-block;
    height: 30px;
    padding: 3px 15px;
    border-radius: 15px;
    font-weight: 500;
    white-space: nowrap;
}

.ota-graphics .dtype-count div span.snmp {
    background: #0056B7;
    color: #FFFFFF;
}

.ota-graphics .dtype-count div span.mqtt {
    background: #E8B203;
    color: #FFFFFF;
}

.ota-graphics .dtype-count div span.tr069 {
    background: #26BF78;
    color: #FFFFFF;
}
.ota-graphics .dtype-count div span.https {
    background: #d823e1;
    color: #FFFFFF;
}

.ota-count {
    width: 100%;
    max-width: 245px;
}

.ota-count div:last-child {
    display: flex;
    flex-direction: column;
}

.ota-count div:first-child span {
    display: inline-block;
    background-color: #f5f5f5;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ota-count div:first-child span.sc {
    background: #FEF5D7;
}

.ota-count div:first-child span.upi {
    background: #EFF4FF;
}

.ota-count div:first-child span.fl {
    background: #FFEFEF;
}

.ota-count div:first-child span.upd {
    background: #EEFAEE;
}

.ota-count div:last-child strong {
    font-size: 24px;
}

.ota-count div:last-child span {
    color: #959595;
    font-size: 14px;
}

.ota-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: solid 1px #DCDCDC;
    padding-bottom: 10px;
    align-items: end;
}

.ota-filter div:first-child {
    display: flex;
    gap: 10px;
}

.ota-filter div:first-child input {
    width: 400px;
}

.ota-filter div:first-child select {
    width: 250px;
}

.ota-filter div:last-child {
    display: flex;
    gap: 10px;
}

.deviceCountChart .recharts-wrapper {
    width: 100% !important;
}

.c-chart,
.c-legend {
    flex: 1;
}

.device-chart {
    /* min-height: 294px; */
}

.device-chart .c-legend {
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    /* white-space: nowrap; */
}
.audit-edge-chart .c-legend{
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
}
.pd10 {
    padding: 10px;
}

.c-legend ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.c-legend ul li {
    margin: 2px 0px;
    flex: 50% 50% 1;
    cursor: pointer;
}

.c-legend ul li span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #CCCCCC;
    margin-right: 10px;
}

.c-legend ul li span.on {
    background: #26BF78;
}

.c-legend ul li span.off {
    background: #FF6B6B;
}

.c-legend ul li span.wait {
    background: #eb8919;
}

.alarm-chart .recharts-wrapper {
    width: 100% !important;
}

.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    margin: 0px 20px;
}
.tab-pagination{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 210px);
}
.tab-pagination .inc-card-table{
    flex: 1;
    max-height: calc(100vh - 243px);
}
.tab-pagination .pagination-container{    
    padding: 0px;
    padding-top: 15px;
    margin: 0px;
}
.pagination-container p {
    font-size: 14px;
    margin: 0px;
}

.pagination-container .pagination-info {
    margin: 0px;
}

.pagination button {
    padding: 0px 10px;
    height: 30px;
    margin: 0 3px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
}

.pagination button:disabled {
    cursor: not-allowed;
}
.switch-box {
    display: flex;
    align-items: center;
    margin: 0 20px;
    font-size: 16px
}

.switch-box .ice-switch {
    margin-right: 6px
}

.ice-switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 22px;
    margin-bottom: 0px;
}

.ice-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.ice-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;
    border-radius: 32px;
}

.ice-switch .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    transition: .4s;
    border-radius: 50%;
}

.ice-switch input:checked+.slider {
    background: #0278E7;
}

/* Container for the chart */
.chart-container {
    position: relative;
    width: 100%;
    /* Adjust width as needed */
}

.chart-container div {
    margin-bottom: -15px;
}

/* Styles for the chart */
.chart {
    width: 100%;
    height: 100%;
}

/* Styles for color range rectangles */
.color-rectangle {
    position: absolute;
    top: 0;
    opacity: 0.4;
    z-index: -1;
}

.apexcharts-legend-series.swatch circle {
    shape-rendering: geometricPrecision;
    width: 10px;
    height: 10px;
    border-radius: 2%;
}

.edge-error-msg {
    padding: 50px 30px;
    text-align: center;
}

.edge-error-msg img {
    height: 80px;
    width: 80px;
    margin-bottom: 30px;
}

.edge-error-msg h2 {
    font-weight: 700;
}

.edge-error-msg button {
    width: 180px;
    border-radius: 8px;
    height: 45px;
    font-size: 18px;
}

.grafna-link a {
    text-decoration: none;
    color: #8f3e7f;

}

.under-development {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
    font-weight: 600;
    font-size: 30px;
    color: #b9b9b9;
}

.radio-buttons input[type="radio"] {
    display: inline-block;
    margin-right: 5px;
}

.radio-buttons label {
    display: inline-block;
    margin-right: 10px;
}

.read-only-field {
    background-color: #f2f2f2;
    /* Light grey background */
    cursor: not-allowed;
    /* Show a not-allowed cursor when hovering over the field */
}

.read-only-input,
.text-btn.primary-btn.disabled,
.text-btn.primary-btn:disabled {
    cursor: not-allowed;
}

.flicker {
    animation: flickerAnimation 0.2s infinite;
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.label-required::after {
    content: " *";
    color: red;
}

.input-wrapper {
    position: relative;
}

.input-wrapper input {
    width: 100%;
    padding-right: 40px;
    /* Make space for the icon */
}

.input-wrapper img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.readonly-field {
    background-color: #e6e6e6 !important;
}

/* =============REPORT=============== */
.inc-report {
    display: flex;
}

.inc-report .report-tab {
    width: 250px;
    padding: 25px 15px 25px 0px;
}

.inc-report .report-tab div.step {
    padding-bottom: 70px;
    display: flex;
    position: relative;
}

.inc-report .report-tab div span,
.inc-report .report-tab div strong {
    display: block;
    text-align: right;
}

.inc-report .report-tab div span {
    font-size: 14px;
}

.inc-report .report-tab div strong {
    font-size: 16px;
}

.step .s-text {
    flex: 1;
    margin-right: 15px;
}

.step .s-dot {
    width: 25px;
    height: 25px;
    background-color: #A2CFFE;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-indent: 100px;
}

.step i.line {
    position: absolute;
    width: 3px;
    height: 70px;
    background-color: #A2CFFE;
    top: 35px;
    right: 11px;
    z-index: 1;
    border-radius: 2px;
}

.step i.line span {
    display: inline-block;
    position: absolute;
    top: 0px;
    width: 3px;
    height: 0px;
    background-color: #0468C8;
    border-radius: 2px;
    transition: 0.5s ease;
    transition-delay: 0.3s;
}

.step.selected .s-dot {
    background-color: #ffffff;
    border: solid 2px #0468C8;
    position: relative;
}

.step.selected .s-dot::before {
    content: '';
    top: 3px;
    left: 3px;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #0468C8;
}

.step.selected i.line span {
    height: 35px;
}

.step.completed .s-dot {
    background-color: #07C271;
    position: relative;
    text-indent: 0px;
    color: #FFFFFF;
    font-size: 13px;
}

.step.completed i.line span {
    display: inline-block;
    width: 3px;
    height: 70px;
    background-color: #07C271;
    border-radius: 2px;
}

.inc-report .report-section {
    background-color: #F6F6F6;
    border-radius: 10px;
    flex: 1;
    padding: 25px 25px 15px 25px;
    height: 79.5vh;
}

.inc-report .report-section .report-container {
    height: 69.5vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.report-container h4 {
    font-size: 16px;
    margin-bottom: 5px;
}

.report-container p {
    font-size: 14px;
    color: #989898;
    margin-bottom: 10px;
}

.report-container .form-label,
.report-container .form-check-label {
    color: #9699A8;
    font-size: 14px;
}

.report-container .mb-35 {
    margin-bottom: 35px;
}

.report-section .report-button {
    padding-top: 15px;
    justify-content: end;
    display: flex;
    gap: 20px;
}

.report-section .report-button button {
    width: 100px;
}

.report-section .form-check-input {
    margin-top: 1px;
}

.form-check-input {
    border-width: 2px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.form-check-input:checked {
    border-color: #07C271;
    position: relative;
    background-color: transparent;
}

.form-check-input:checked::before {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    border-radius: 50%;
    z-index: 1;
    width: 10px;
    height: 10px;
    background-color: #07C271;
}

.report-radio {
    display: flex;
    gap: 30px;
    align-items: center;
}

.report-radio div {
    display: inline-flex;
    max-width: 200px;
    width: 100%;
}

/* =========================ACCORDION================= */
.accordion-item {
    margin-bottom: 5px;
    border: solid 1px #D8D8D8;
}

.accordion-header {
    position: relative;
}

.accordion-header .checkbox {
    position: absolute;
    z-index: 5;
    top: 18px;
    left: 15px;
}

.accordion-header .accordion-button {
    padding-left: 25px;
    font-weight: 600;
}

#accordionFilter .accordion-header .accordion-button {
    padding-left: 45px;
}

.accordion-body .inc-card-table {
    min-height: auto;
}

.accordion-body .r-filter,
.accordion-body .p-report {
    width: 400px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.accordion-body .r-filter .form-label,
.accordion-body .p-report .form-label {
    margin-bottom: 0px;
    flex: 1;
}

.accordion-body .r-filter input,
.accordion-body .p-report input {
    flex-basis: 280px;
}

.accordion-body .r-profile {
    margin-left: 20px;
    width: auto;
    display: flex;
    gap: 15px;
    align-items: center;
    flex: 1;
}

.accordion-body .r-profile .form-label {
    margin-bottom: 0px;
}

.accordion-body .r-profile input {
    flex-basis: 550px;
}

.accordion-footer{
    padding-top: 15px;
    justify-content: end;
    display: flex;
    gap: 10px;
    margin-top: 10px;
}
/* ==============MODULE LINK============ */
.link-module {
    margin-top: 40px;
    position: relative;
}

.master-module {
    border-radius: 10px;    
    position: relative;
    z-index: 2;
}

.master-module .mm-title {
    border-radius: 10px 10px 0px 0px;    
    padding: 10px 20px;
    font-weight: 500;
}

.master-module .mm-body {
    padding: 15px;
    padding-bottom: 10px;
}

.master-module .mm-body .inc-card-table,
.slave-module .sm-body .inc-card-table {
    min-height: auto;
}

.master-module .link {
    position: absolute;
    bottom: -10px;
    left: -10px;    
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.master-module .link i {
    color: #0278E7;
}

.slave-module {
    border-radius: 10px;    
    margin-left: 50px;
    margin-top: 15px;
}

.slave-module .sm-title {
    border-radius: 10px 10px 0px 0px;    
    padding: 7px 20px;
    font-weight: 500;
    font-size: 14px;
    position: relative;
}

.slave-module .sm-title span.line {
    position: absolute;
    left: -45px;
    top: 19px;
    width: 45px;
    border-bottom: solid 1px #0278E7;
}

.slave-module .sm-title span.line::before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0px;
    bottom: 0px;
    width: 1px;
    height: 150px;
    background: #0278E7;
}

.slave-module .sm-title span.line::after {
    content: '';
    position: absolute;
    z-index: 2;
    left: -5px;
    bottom: -5px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #C2E0FC;
}

.slave-module .sm-body {
    padding: 15px;
    padding-bottom: 5px;
}

.inc-overview-grid {
    display: grid;
    /* grid-template-columns: 3fr 1fr; */
    grid-gap: 20px;
}
.inc-overview-grid{
    grid-template-columns:1fr;
}
.inc-overview-grid:has(>.inc-card){
    grid-template-columns:3fr 1fr;
}

.inc-grid-col-1 {
    display: grid;
    row-gap: 20px;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr) 500px;
}

.inc-grid-col-1 .box5 {
    grid-column: 2/4;
}

.on-trend .recharts-wrapper {
    margin-top: 20px;
}

.chart-wrapper {
    width: 100%;
    margin: 0 auto;
}

/* ==================TROUBLESHOOT============== */
.ookla-test {
    max-width: 200px;
}

.ookla-test i {
    color: #FF0000;
    margin-right: 2px;
    font-size: 12px;
}

.t-device {
    width: 100px;
    text-align: center;
}

.t-device-info {
    margin: 5px 0px;
}

.t-device-info p {
    margin: 0px;
}

.t-device-info strong,
.t-device-info a {
    font-weight: 600;
    text-decoration: none;
}

.wd-usage-chart hr {
    margin: 10px 0px 20px 0px;
}

.wd-usage-chart .wd-d-port {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 9px;
}

.wd-usage-chart .wd-d-port h4 {
    color: #858585;
    font-weight: 500;
    font-size: 14px;
}
.wd-usage-chart .wd-d-port + .recharts-responsive-container {
    position: relative;
    z-index: 2;
}

.wd-usage-chart .wd-d-port + .recharts-responsive-container {
    position: relative;
    z-index: 2;
}

.wd-usage-chart:hover .recharts-responsive-container {
    position: relative;
    z-index: 99;
}

.client-con-type {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    margin-bottom: 15px;
}

.client-con-type label {
    display: flex;
    gap: 5px;
    align-items: center;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 0px;
}

/* ==================== TWO COLUMN ==================== */
.two-column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}

/* ==================== TROUBLESHOOT ==================== */
.troubleshoot-option {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}

.troubleshoot-option label {
    padding: 0.75rem 1rem 0.75rem 2.2rem;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
}

.troubleshoot-option label input {
    position: absolute;
    top: 15px;
    left: 15px;
}

.troubleshoot-option label h4 {
    font-size: 16px;
}

.troubleshoot-option label p {
    font-size: 14px;
    margin-bottom: 0px;
}

.no-data-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}


.round-border {
    border-radius: 10px;
    padding: 8px 10px;
}


.right-button-group {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.right-button-group div {
    display: flex;
    gap: 0.5rem;
}

.sideTab-container {
    display: grid;
    grid-template-columns: 250px 1fr;
    column-gap: 15px;
    height: 78vh;
}

.sideTab-container.wired-sideTab {
    grid-template-columns: 270px 1fr;
}

.sideTab-container .inc-side-tabcontainer {
    position: relative;
}

.inc-side-tab {
    padding-right: 15px;
}

.inc-side-tab ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.inc-side-tab ul li button {
    font-size: 14px;
    font-weight: 600;
    padding: 15px 20px;
    border-radius: 8px;
    margin-bottom: 2px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inc-side-tab ul li button i {
    display: none;
    font-size: 18px;
}

.inc-side-tab ul li button.active i {
    display: inline-block;
}

.band-pills {
    display: flex;
    gap: 5px;
}

.band-pills span {
    display: inline-block;
    height: 20px;
    border-radius: 3px;
    padding: 0px 5px;
    color: #ffffff;
}

.band-pills span.yellow {
    background: #FEC300;
}

.band-pills span.blue {
    background: #00B9FE;
}

.add-ssid-body h4 {
    font-size: 16px;
}

.add-ssid-body .d-flex {
    align-items: center;
}

.add-ssid-body .switch-box {
    margin-left: 0px;
    justify-content: space-between;
}

.add-ssid-body .switch-box strong {
    font-size: 14px;
    margin-right: 15px;
}

.border-line {
    height: 30px;
    position: relative;
}

.border-line:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 15px;
}

.editssid-scroll {
    overflow: auto;
    overflow-x: hidden;
    height: 72vh;
}

.checkbox-group label.checkbox {
    width: auto;
}

.checkbox-group label.checkbox strong {
    margin-left: 24px;
    position: relative;
    top: -2px;
}

.radio-group {
    display: flex;
    gap: 20px;
    align-items: center;
}

.radio-group .form-check {
    display: flex;
}

.radio-group .form-check label {
    margin-bottom: 0px;
    margin-top: 2px;
    font-size: 16px;
    font-weight: 600;
}

.wifi-settings {
    font-size: 14px;
}

.wifi-settings .switch-box {
    justify-content: space-between;
    height: 40px;
}

.wifi-footer {
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: end;
    gap: 10px;
    padding-top: 15px;
    right: 20px;
    left: 0px;
    width: 100%;
}

.wifi-footer button {
    height: 40px;
    min-width: 100px;
}

/* ================WIFI SCHEDULE============ */
.schedule-wrapper {
    overflow: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 280px);
}

.schedule-wrapper .schedule-item {
    padding: 15px 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.schedule-wrapper .schedule-item .schedule-icon {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.schedule-icon {
    font-size: 28px;
    height: 46px;
}

.schedule-wrapper .schedule-item .schedule-detail {
    flex: 1;
}

.schedule-detail h5 {
    margin: 0px;
    font-size: 16px;
}

.schedule-detail p {
    margin: 5px 0px 0px 0px;
    font-size: 14px;
}

.schedule-wrapper .schedule-item .schedule-button {
    width: 100px;
    display: flex;
    justify-content: center;
}
.schedule-height{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 250px);
}
.schedule-week p{
    font-size: 14px;
    margin-bottom: 10px;
    color: #999999;
}
.schedule-days .sc-day-title {
    padding: 20px 0px 10px 0px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.schedule-days .sc-day-title h4 {
    margin: 0px;
}

.schedule-days .sc-day-title button {
    border: 0px;
    border-radius: 5px;
    font-size: 14px;
}

.schedule-time {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}

.schedule-time-box {
    padding: 25px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    position: relative;
}

.schedule-time-box h4 {
    font-size: 14px;
}

.schedule-time-box input {
    width: 100%;
    border-width: 0px 0px 1px 0px;
    height: 40px;
    padding: 0px;
    background-color: transparent;
}

.schedule-time-box .css-jupps9-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 0px;
    outline: 0px;
}
.schedule-time-box .css-jupps9-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.schedule-time-box .css-jupps9-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-radius: 0px;
    outline: 0px;
    border-width: 0px;
}
.schedule-time-box .delete-slot {
    position: absolute;
    top: 10px;
    right: 15px;
    border: 0px;
    font-size: 14px;
    border-radius: 5px;
    background: transparent;
}

.schedule-time-box:hover .delete-slot {
    color: #FFFFFF;
    background-color: #FF0000;
}

.schedule-slot-scroller{
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 500px);
    min-height: 100px;
    padding-right: 15px;
}

.tab-form label {
    color: #9699A8;
    font-size: 14px;
}
.tab-form .form-check label{
    margin-bottom: 0px;
    top: 4px;
    position: relative;
}
.tab-form input[type='text'],
.tab-form input[type='password'] {
    height: 50px;
}
.login-form-container .line-textbox input::placeholder,
.tab-form input::placeholder {
    color: #CCCCCC !important;
  } 

.tab-form .switch-box {
    margin: 0px;
    justify-content: space-between;
}
.pointer{
    cursor: pointer;
}
.or-seperator{    
    display: flex;
    justify-content: center;
    position: relative;
}
.or-seperator::before{
    content: '';
    position: absolute;
    left: 0px;
    top:15px;
    width: 100%;    
 }
.or-seperator span{
    display: inline-block;
    border-radius: 17px;
    height: 34px;
    width: 34px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}
.header-notification {
    position: relative;
    display: inline-block;
}

.icon.notifications {
    font-size: 24px;
    cursor: pointer;
    position: relative;
}
.notifications-count span{
    background-color: red;
    color: white;
    height: 15px;
    line-height: 13px;
    border-radius: 8px;
    font-size: 10px;
    padding: 1px 5px;
    position: absolute;
    top:-8px;
    left: 25%;
    z-index: 2;
}
.badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
}

.domain-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap:20px;
}
.domain-wrapper .domain-list{
    padding: 25px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}
.domain-wrapper .domain-list h3{
    font-size: 16px;
    margin: 0px;
}

.domain-wrapper .domain-list button {
    border: 0px;
    font-size: 14px;
    border-radius: 5px;
    background: transparent;
}

.domain-wrapper .domain-list:hover button {
    color: #FFFFFF;
    background-color: #FF0000;
}


.tab-inside-tab{
    margin-bottom: 20px;
}

.traffic-btn{
    display: flex;
    gap: 10px;
    justify-content: end;
}

.drag-file-box{
    position: relative;
    background: #f5f8f9;
    border: 2px dashed #ababab;
    height: 50px;
    padding: 12px 0;
    text-align: center;
    max-width: 700px;
    margin-bottom: 20px;
    color: #666666;
}
.drag-file-box span{
    text-decoration: underline;
    font-weight: 500;
    color: #000000;
}
.drag-file-box input[type=file] {
    width: 100%;
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

#policiesTabContent{
    overflow: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 280px);
}
.vlan-text{
    margin: 20px 0px;
}
.vlan-text p{
    color: #666666;
    line-height: 24px;
}
.vlan-text strong{
    color: #000000;
    font-weight: 600;
}

.wifi-ssid{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.port-member ul{
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}
.port-member ul li{
    color: #6d6f70;
}
.port-member ul li i,
.port-member ul li strong,
.port-member ul li img{
    margin-right: 10px;
    font-size: 14px;
    width: 14px;
}
.colorBlue {
    color: #0af;
}
.colorGreen {
    color: #00d76f;
}
.colorLightGray {
    color: #e8e9f0;
}
.colorBlack {
    color: #0b0b0b;
}
.colorRed {
    color: #fc3858;
}
.colorPurple{
    color: #6E2585;
}
.port-button{
    display: flex;
    gap: 10px;
    justify-content: center;
    border-top:solid 1px #F4F4F4;
    padding-top: 10px;
    margin-top: 15px;
}

/* ============TAB TITLE=========== */
.tab-container-title{
    padding: 20px 0px;
}
.tab-container-title h4{
    position: relative;
    line-height: 20px;
    margin-left: 8px;
}
.tab-container-title h4::before {
    content: '';
    position: absolute;
    top: 0px;
    left: -7px;
    height: 20px;
    width: 3px;
    background: #C2ADEF;
    border-radius: 2px;
}
.tab-container-title h4::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: -7px;
    height: 10px;
    width: 3px;
    background: #91DBF2;
    border-radius: 2px;
}
.w-scroll{
    overflow: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 280px);
}
/* ============PORT=========== */
.port-status{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    font-size: 14px;
    font-weight: 600;
}
.port-status div{
    max-width: 200px;
    display: flex;
    gap: 5px;
    align-items: center;
}
.port-status i{    
    font-size: 12px;
}
.router-port{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
}
.router-port .port-container:first-child{
    margin-bottom: 25px;
}


.router-port.left{
    justify-content: left;
}
.polygon{    
    height: 22px;
    width: calc(100% - 40px);
    margin-left: 20px;
    position: relative;
    background: url(../images/router-back.png) center center no-repeat;
}
.polygon::before{
    content: '';
    position: absolute;
    left: -10px;
    top: -5px;
    height: 31px;
    width: 1px;    
    rotate: 45deg;
}
.polygon::after{
    content: '';
    position: absolute;
    right: -10px;
    top: -5px;
    height: 31px;
    width: 1px;
    rotate: -45deg;
}
.mxw-1260{
    max-width: 1260px;
}
.mxw-850{
    max-width: 850px;
}
.mxw-650{
    max-width: 650px;
}
.mxw-500{
    max-width: 500px;
}
.port-box{
    min-width: 120px;
    min-height: 40px;
    max-width: 1260px;
    overflow: auto;
}
.port-box ul{
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
}

.port-box ul.port18{
    display: grid;
    grid-template-columns: repeat(9, 60px);
}
.port-box ul.port24{
    display: grid;
    grid-template-columns: repeat(12, 60px);
}
.port-box ul.port28{
    display: grid;
    grid-template-columns: repeat(14, 60px);
}
.port-box ul.port48{
    display: grid;
    grid-template-columns: repeat(24, 60px);
}
.port-box ul.port52{
    display: grid;
    grid-template-columns: repeat(26, 60px);
}
.port-box ul.port54{
    display: grid;
    grid-template-columns: repeat(27, 60px);
}
.port-box ul li{
    flex: 1;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    background: url(../images/d-port-icon.svg) center center no-repeat;
}
.port-box ul li.assign{
    background: url(../images/db-port-icon.svg) center center no-repeat;
}
.port-box ul li span{
    background-color: #FFFFFF;
    color: #00d76f;
    display: inline-block;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 3px;
    top: 3px;
    border-radius: 8px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.device-port-detail{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 15px;
    row-gap: 30px;
}
.device-port-detail strong,
.device-port-detail span{
    display: block;
}
.device-port-detail strong{
    font-weight: 600;
}
.device-port-detail span{
    font-size: 14px;
}


/* ====================NOTIFICATION================== */
.top-notification {
    position: absolute;
    z-index: 12;
    top: 40px;
    right: -130px;
    width: 380px;
    border-radius: 5px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
}
.notification-title{
    font-size: 18px;
    padding: 10px 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notification-title span.mark-all,
span.mark-all{
    font-size: 14px;
    font-weight: 500;
    color: #2a72e0;
    cursor: pointer;
}
.notification-item{
    padding: 10px 20px; 
    cursor: pointer;
}
.notification-item div:first-child{
    display: flex;
    justify-content: space-between;       
}
.notification-item div:last-child span{
    display: block;
    overflow: hidden;    
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 340px;    
}
.notification-item div strong{
    font-size: 14px;
    font-weight: 600;
    display: block;
    overflow: hidden;    
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px; 
}
.notification-item div span{
    font-size: 12px;
}
.top-notification .view-all-notification{
    padding: 10px 10px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.notification-container h4{
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}
.notification-container h4:first-child{
    margin-top: 0px;
}
.notification-container h4 span{
    background-color: #fd3c9720;
    color: #fd3c97;
    margin-left: 10px;
    height: 20px;
    width: 20px;
    display: inline-block;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.notification-container .notification-list{    
    padding: 15px;
    border-radius: 8px;
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
}
.notification-list .n-icon span{
    border-radius: 50%;
    display: inline-block;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.notification-list .n-icon span i{
    font-size: 20px;
}
.notification-list .n-info{
    flex: 1;
}
.notification-list .n-info h6 small{
    font-size: 14px;
    margin-left: 10px;
}
.notification-list .n-info p{
    margin: 0px;
    font-size: 14px;    
}
.notification-list .n-info h6,
.notification-list .n-info p{
    max-width: 90%;
}

.notification-list.unread{
    border: solid 1px var(--inc-unread-border);
    cursor: pointer;
}
.notification-list.unread .n-info h6 small, 
.notification-list.unread .n-info p{
    color: var(--inc-black);
    font-weight: 600;
}
.notification-footer{
    display: flex;
}
.notification-footer span{
    display: inline-block;
    padding: 10px 10px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    flex: 1;
}
.notification-footer span:first-child{
    border-right: solid 1px var(--inc-border);
}
.load-list{
    padding: 20px 0px;
    border-bottom: solid 1px var(--inc-border);
}
.load-type{
    display: flex;
    gap: 15px;
    align-items: center;
}
.load-type .checkbox{
    width: auto;
    height: auto;
}
.load-type .checkbox p{
    margin: 0px;
    margin-left: 30px;
}

.edit-custom-link a{
    color:#0278e7;
    text-decoration: none;
    font-weight: 500;
}
.edit-custom-link a i{
    margin-right: 5px;
}
.label-check{
    display: flex;
    align-items: center;
    gap:10px;
}


/* ===========POE SCHEDULE============= */
.poe-schedule-banner{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(100vh - 200px);
}
.poe-schedule-banner img{
    width:70px;
    opacity: 0.6;
}
.poe-schedule-banner button{
    display: inline-flex;
}
.poe-schedule .accordion-item{
    margin-bottom: 0px;
}
.schedule-action{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding:10px 0px 20px 0px;
    margin-bottom: 20px;
}
.schedule-action strong{
    font-weight: 500;
}
.poe-schdule-time{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.poe-schdule-time span,
.poe-schdule-time strong{
    display: block;
}
.poe-schdule-time strong{
    font-weight: 500;
    margin-top: 5px;
}
.schedule-btn{
    text-align: right;    
}
.schedule-btn button{
    height: 1.75rem;
    margin-left: 5px;
    line-height: 13px;
    font-size: 14px;
}
/* Add this CSS to your stylesheet */
.kommunicate-widget-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; 
  }
  
.device-pic img{
    height: 20px;
}
.no-data-available {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure it takes full height of the parent */
    text-align: center; /* Center the text */
    font-size: 1.5em; /* Increase font size as needed */
    color: #888; /* Change color as needed */
  }
  
/* .custom-legend.category{
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 0px;
    margin-top: 15px;
    font-size: 14px;
}
.custom-legend.category li{
    display: flex;
    align-items: center;
}
.custom-legend.category li span{
    margin-top: -2px;
} */


.category-label {
    display: flex;
    align-items: center;
}
.category-label ul{
    list-style: none;
    padding: 0px;
}
.category-label ul li{
    line-height: 28px;
}
.category-label ul li i{
    font-size: 14px;
    margin-right: 5px;
}
.category-label ul li.ms i{
    color: #ff6b6b;
}
.category-label ul li.th i{
    color: #118ab2;
}
.category-label ul li.ds i{
    color: #26bf78;
}
.category-label ul li.dc i{
    color: #ffad01;
}
/* Add this to your CSS file */
.MuiTouchRipple-root .MuiSvgIcon-root {
    font-size: 16px; /* Adjust the size as needed */
    /* You can also adjust other properties if needed */
  }
  .text-btn.primary-btn:disabled {
    background-color: #e0e0e0; /* Light gray background */
    color: #a0a0a0; /* Gray text */
    cursor: not-allowed; /* Change cursor style */
  }
  .text-btn:disabled ,
  .text-btn:disabled:hover{
    background-color: #e0e0e0; /* Light gray background */
    color: #a0a0a0; /* Gray text */
    cursor: not-allowed; /* Change cursor style */
  }

    

.radius-help span{
    margin-left: 8px;
    font-size: 18px;
    cursor: pointer;
}
.radius-help-modal ul{
    padding: 0px;
    list-style: none;
}  
.radius-help-modal ul li{
    display: flex;
    text-align: start;
    gap: 10px;
    margin: 5px 0px 10px 0px;
}
.radius-help-modal ul li strong{
    display: block;
    font-weight: 600;
}

.radius-device{
    border-radius: 8px;
    margin-bottom: 20px;
}
.radius-device-title{
    padding: 15px 20px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.radius-device-title h3{
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
}
.radius-device-body{
    padding: 20px;
}
.radius-device-footer{
    padding: 15px 20px;
    display: flex;
    gap: 10px;
    justify-content: right;
}
.port {
    /* existing styles for ports */
  }
  
  .port.tagged {
    background-color: blue; /* Change to the desired blue color */
    color: white; /* Adjust text color if needed */
  }
  
  .double-click-box{
    width: fit-content;
    position: relative;
  }
  .double-click-box input{
    padding-right: 45px;
    width: 180px;
  }
  .double-click-box i{
    position: absolute;
    right: 8px;
    top:6px;
    z-index: 2;
    background-color: #FFFFFF;
    cursor: pointer;
  }
  .double-click-box i.fa-times{
    color: red;    
  }
  .double-click-box i.fa-check{
    color: green;
    margin-right: 18px;
  }

.notification-title .switch-box{
    margin: 0px;
}
.notification-title .ice-switch{
    width: 34px;
    height: 16px;
}
.notification-title .ice-switch .slider:before{
    height: 12px;
    width: 12px;
}
.notification-title input:checked+.slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}
.no-notifications-message{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    
}

.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 7.5px 14px !important;
    padding-right: 0 !important;
}
.css-1ll44ll-MuiOutlinedInput-notchedOutline{
    border: 0px;
    border-color: transparent !important;
}
.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: transparent;
}
.weekdays{
    display: flex;
    gap: 10px;
}
.weekdays label{
    width: 28px;
    height: 28px;
    margin: 0;
    cursor: pointer;
}
.weekdays label span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    position: relative;
    border-radius: 100%;    
    text-align: center;
    font-size: 14px;    
    line-height: 28px;
}
.weekdays label input{
    position: absolute;
    width: 28px;
    height: 28px;
    appearance: none;
}
.weekdays label [type=checkbox]:checked+span {
    background: #00d76f;
    color: #fff;
    border-color: #00d76f;
}
.checkbox-label{
    display: flex;
    gap: 10px;
    align-items: center;
    padding-left: 0px;
}
.checkbox-label label{
    margin: 0px;
}
/* Slider container */
.custom-slider {
    position: relative;
    height: 6px;
    border-radius: 3px;
    width: 80%; /* Increased width for a longer slider */
  }
  
   .custom-track {
    position: absolute;
    height: 6px;
    border-radius: 3px;
    /* background: linear-gradient(
      to right,
      green 0%,
      orange 50%,
      pink 75%,
      red 100%
    ); */
  } 
  
  .custom-thumb {
    height: 25px;
    width: 25px;
    background-color: #077BE7;
    border-radius: 50%;
    position: relative;
    top: -32px;    
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin-left: -10px;
  }
  .custom-thumb[aria-valuenow="0"]{
    margin-left: -10px;
  }
  .custom-thumb[aria-valuenow="200"]{
    margin-left: 10px;
  }
  
  .custom-thumb::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid #077BE7;
  }
  
  .custom-thumb:focus {
    outline: none;
    box-shadow: 0 0 10px #077BE7;
  }
  
  /* Make the slider line visible */
  /* .custom-slider {
    background-color: #ddd;
  } */
  
  /* .custom-slider .custom-track {
    background: linear-gradient(
      to right,
      green,
      orange,
      pink,
      red
    ); 
  } */


  
.load-slider{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.load-slider span{
    font-weight: bold;
}
.load-slider .slider-value{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 30px;
}

.port-box li {
    position: relative;
    cursor: pointer;
    padding: 10px;
  }
  
  .port-box li.selected::after {
    content: "\f058";
    position: absolute;
    top: 2px;
    right: 2px;
    color: #00d76f;
    display: inline-block;
    font: normal normal normal 16px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
  }
  
  .port-box li.tagged {
    background-color: #d4edda; /* Example for tagged port */
  }
  
  .summary-dropdown{
    display: flex;
    gap: 20px;
  }
  .summary-dropdown .dropdown-menu{
    right: 30px !important;
  }
  .snmp-config-link ul li button div{
    flex: 1;
  }
  .snmp-config-link ul li button div i{
    float: right;
  }
  .snmp-config-link ul li button span{
    display: block;
    font-weight: normal;
    font-size: 11px;
    color: #434343;
  }

  .snmp-config-tab .form-select{
    height: 50px;
    border-radius: 5px;
  }

  /* input[type='password'] {
    font: large Verdana,sans-serif;
    letter-spacing: -1px;
  } */

  .tab-form input::placeholder{
    font-weight: normal;
    font-size: 16px;
  }

  .event-tab{
    padding-right: 5px;
  }
  .event-tab .switch-box{
    border: solid 1px #ccc;
    background: #fdfdfd;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .event-tab .switch-box strong{
    font-weight: 500;
  }
  .event-tab .switch-box .ice-switch{
    margin-right: 0px;
  }

  .inc-breadcrumb{
    position: absolute;
    top: 35px;
    z-index: 99;
  }
  .inc-breadcrumb ol{
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    gap: 6px;
  }
  .inc-breadcrumb ol li{
    font-size: 14px;
  } 
  .inc-breadcrumb ol li a{
    text-decoration: none;
  } 

  .inc-breadcrumb ol li.active::before{
    content: "\f105";
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    margin-right: 6px;
  }
  
  .disabled-menu {
    opacity: 0.5; /* Light greyed out */
    cursor: not-allowed;
    pointer-events: none; /* Prevent clicks */
  }
  .disabled-link {
    pointer-events: none; /* Disables clicking */
    opacity: 0.5; /* Makes it look disabled */
    cursor: not-allowed;
  }
  .visible-password{
    position: relative;
  }
  .guide-msg {
    margin-left: 10px;
    position: relative;
    cursor: pointer;
}

.guide-msg i {
    color: #333;
}

.guide-msg span {
    width: 300px;  /* Adjusted for better fitting */
    border-radius: 8px;
    background: #f4f4f4;
    padding: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    margin-bottom: 8px;
    font-size: 14px;
    color: #666666;
    text-align: left;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure it appears above other elements */
}

.guide-msg span::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #F4F4F4;
    clip-path: polygon(54% 0, 3% 0, 64% 69%);
    height: 10px;
    width: 20px;
}

.guide-msg:hover span {
    visibility: visible;
    opacity: 1;
}

  