:root {
  --inc-body-bg: #ECEEF0;
  --inc-body-color: #212529;

  --inc-health-color: #333333;
  --inc-health-img: none;

  --inc-Nav-bg: #FFFFFF;
  --inc-nav-color: #4C4C4C;
  --inc-nav-active-bg: #EEF8FD;
  --inc-nav-active-color: #28ACE2;
  --inc-nav-active-border: #28ACE2;
  --inc-nav-dropdown-bg: #FFFFFF;
  --inc-nav-icon-hcolor: invert(53%) sepia(97%) saturate(657%) hue-rotate(164deg) brightness(93%) contrast(90%);

  --inc-header-bg: #FFFFFF;
  --inc-header-title-color: #333333;
  --inc-header-user-color: #212529;
  --inc-header-icon-color: #212529;
  --inc-header-toggle-bg: #CCCCCC;
  --inc-header-toggle-a-bg:#0278E7;
  --inc-toggle-off-bg: #CCCCCC;

  --inc-card-bg: #FFFFFF;
  --inc-card-title-color: #333333;
  --inc-otacard-bg: #FFFFFF;
  --inc-otacard-border: #DCDCDC;
  --inc-report-card-bg: #F6F6F6;
  --inc-step-color:#434343;

  --inc-audit-title-color: #8B8B8B;

  --inc-search-bg: #EEF8FD;
  --inc-search-color: #8D9DD5;
  --inc-search-border-color: #dceff7;
  --inc-primary-btn-bg: #0278E7;
  --inc-primary-btn-color: #FFFFFF;
  --inc-text-btn-bg: #f3f6f8;
  --inc-text-btn-color: #444444;
  --inc-icon-btn-bg: #f3f6f8;
  --inc-icon-btn-color: #444444;
  --inc-icon-btn-hbg: #0278E7;
  --inc-icon-btn-hcolor: #FFFFFF;
  --inc-disable-bg:#E6E6E6;
  --inc-disable-color:#808A96;

  --inc-page-border:#acdef3;
  --inc-page-bg:#EEF8FD;
  --inc-page-disable-color:#CCCCCC;

  --inc-textbox-bg: #FFFFFF;
  --inc-textbox-color: #444444;
  --inc-placeholder-color: #444444;
  --inc-textbox-border: #d5d8db;

  --inc-border: #EBEBEB;
  --inc-time-border: #CCCCCC;

  --inc-modal-bg: #FFFFFF;
  --inc-modal-color: #666666;
  --inc-modal-border: transparent;
  --inc-modal-title-color: #077BE7;
  --inc-alert-title-color:#333333;

  --inc-tab-color: #818181;
  --inc-tab-acolor: #212944;
  --inc-tab-aborder: #28ACE2;
  
  --inc-tab-dbg: #E6E6E6;
  --inc-tab-dcolor: #808A96;
  --inc-tab-dabg: #28ACE2;
  --inc-tab-dacolor: #FFFFFF;

  --inc-table-bg: #EEF8FD;
  --inc-table-hbg: #e1f5ff;
  --inc-table-color: #5A5D5A;
  --inc-table-th-bg: #FFFFFF;
  --inc-table-th-color: #000000;
  --inc-table-icon-color: #888888;
  --inc-table-icon-dscolor: #CCCCCC;
  --inc-table-icon-hcolor: #333333;
  --inc-info-color: #8B8B8B;

  --inc-popup-bg: #0C0E24;
  --inc-popup-border: #2D345C;
  --inc-popup-header-color: #FFFFFF;
  --inc-popup-color: #8595CB;
  --inc-popup-fill-btn-bg: #344197;
  --inc-popup-fill-btn-color: #FFFFFF;
  --inc-popup-btn-border: #1D234A;
  --inc-popup-btn-border-color: #8696CC;

  --inc-checkbox-bg: #0278E7;
  --inc-checkbox-border: #cbe6f1;

  --inc-dr-status: #EEF8FD;

  --inc-table-sort-icon: #9D9D9D;
  --inc-table-sort-hicon: #333333;

  --inc-button-bg: #28ACE2;
  --inc-button-color: #FFFFFF;
  --inc-btn-outline-bg: #FFFFFF;
  --inc-btn-outline-color: #077BE7;
  --inc-btn-outline-border: #D5D8DB;
  --inc-btn-outline-hbg: #F4F4F4;
  --inc-btn-outline-hcolor: #077BE7;
  --inc-btn-outline-hborder: #c0c2c4;
  --inc-btn-fill-bg: #077BE7;
  --inc-btn-fill-color: #FFFFFF;
  --inc-btn-fill-hbg: #086AC5;
  --inc-btn-fill-hcolor: #FFFFFF;

  --inc-permission-border: #e7e7e7;
  --inc-permission-nbg: #eef8fd;
  --inc-permission-ncolor: #212529;
  --inc-permission-nborder: #a1d9f5;

  --inc-fault-sep-color:#EEF8FD;

  --inc-dropdown-hbg:#f8f9fa;
  --inc-dropdown-hcolor:#212529;

  --inc-online:#E6FFEC;
  --inc-offline:#FFEFEF;
  --inc-awaiting:#fff1d3;

  --inc-accordian-bg:#FFFFFF;
  --inc-accordian-border:#F1F1F1;

  --inc-trouble-bg: #F8F8F8;

  --inc-sidenav-active-bg:#EEF8FD;
  --inc-sidenav-active-color:#000000;

  --inc-icon-color:brightness(0) saturate(100%) invert(23%) sepia(15%) saturate(13%) hue-rotate(332deg) brightness(93%) contrast(83%);
  --inc-icon-h-color:brightness(0) saturate(100%) invert(97%) sepia(5%) saturate(435%) hue-rotate(265deg) brightness(116%) contrast(100%);

  --inc-schedule-bg:#f1f1f1;
  --inc-time-color:#b7b7b7;
  --inc-schedule-name-color:#000000;

  --inc-notification-bg:#FDFDFD;
  --inc-notification-ibg:#efefef;
  --inc-notification-color:#999999;

  --inc-label-color: #707070;

  --inc-black:#000000;
  --inc-unread-border:#CCCCCC;

  --inc-device-border:#DDDDDD;

  --inc-accordian-arrow:invert(0%) sepia(7%) saturate(29%) hue-rotate(342deg) brightness(95%) contrast(108%);

  --inc-link-border:#E8E8E8;
  --inc-link-slave-title-bg:#F0F8FF;
}

[data-theme=dark] {
  color-scheme: dark;

  --inc-body-bg: #0E122A;
  --inc-body-color: #8D9DD5;

  --inc-health-color: #8d9dd5;
  --inc-health-img: invert(1);

  --inc-Nav-bg: #020515;
  --inc-nav-color: #8696CC;
  --inc-nav-active-bg: #344197;
  --inc-nav-active-color: #FFFFFF;
  --inc-nav-active-border: #FFFFFF;
  --inc-nav-dropdown-bg: #020515;
  --inc-nav-icon-color: invert(66%) sepia(55%) saturate(473%) hue-rotate(195deg) brightness(83%) contrast(92%);
  --inc-nav-icon-hcolor: invert(100%) sepia(100%) saturate(2%) hue-rotate(214deg) brightness(110%) contrast(101%);

  --inc-header-bg: #020515;
  --inc-header-title-color: #FFFFFF;
  --inc-header-user-color: #8D9DD5;
  --inc-header-icon-color: #8D9DD5;
  --inc-header-toggle-bg: #344197;
  --inc-header-toggle-a-bg:#344197;
  --inc-toggle-off-bg: #1e2555;

  --inc-card-bg: #020515;
  --inc-card-title-color: #FFFFFF;
  --inc-otacard-bg: #0e122a;
  --inc-otacard-border: #1e2555;
  --inc-report-card-bg: #0e122a;
  --inc-step-color:#FFFFFF;  

  --inc-audit-title-color: #FFFFFF;

  --inc-search-bg: #141732;
  --inc-search-color: #8D9DD5;
  --inc-search-border-color: #141732;
  --inc-primary-btn-bg: #344197;
  --inc-primary-btn-color: #FFFFFF;
  --inc-text-btn-bg: #1A1E3A;
  --inc-text-btn-color: #FFFFFF;
  --inc-icon-btn-bg: #1A1E3A;
  --inc-icon-btn-color: #FFFFFF;
  --inc-icon-btn-hbg: #344197;
  --inc-icon-btn-hcolor: #FFFFFF;
  --inc-disable-bg:#181e44;
  --inc-disable-color:#3d4a9d;

  --inc-page-border:#1a1e3a;
  --inc-page-bg:#1a1e3a;
  --inc-page-disable-color:#3f456f;

  --inc-textbox-bg: #141732;
  --inc-textbox-color: #FFFFFF;
  --inc-placeholder-color: #5b6590;
  --inc-textbox-border: #252949;

  --inc-border: rgba(45, 52, 92, 0.5);
  --inc-time-border: rgba(45, 52, 92, 0.5);

  --inc-modal-bg: #0C0E24;
  --inc-modal-color: #8D9DD5;
  --inc-modal-border: #2D345C;
  --inc-modal-title-color: #FFFFFF;
  --inc-alert-title-color:#FFFFFF;

  --inc-tab-color: #8696cc;
  --inc-tab-acolor: #FFFFFF;
  --inc-tab-aborder: #28ACE2;

  --inc-table-bg: #0E122A;
  --inc-table-hbg: #131a3f;
  --inc-table-color: #8D9DD5;
  --inc-table-th-bg: #020515;
  --inc-table-th-color: #FFFFFF;
  --inc-table-icon-color: #8D9DD5;
  --inc-table-icon-dscolor: #586696;
  --inc-table-icon-hcolor: #FFFFFF;
  --inc-info-color: #8D9DD5;

  --inc-popup-bg: #0C0E24;
  --inc-popup-border: #2D345C;
  --inc-popup-header-color: #FFFFFF;
  --inc-popup-color: #8595CB;
  --inc-popup-fill-btn-bg: #344197;
  --inc-popup-fill-btn-color: #FFFFFF;
  --inc-popup-btn-border: #1D234A;
  --inc-popup-btn-border-color: #8696CC;

  --inc-checkbox-bg: #344197;
  --inc-checkbox-border: #333853;

  --inc-dr-status: #020c41;

  --inc-table-sort-icon: #5B6590;
  --inc-table-sort-hicon: #5d70d9;

  --inc-tab-dbg: #141732;
  --inc-tab-dcolor: #8696cc;
  --inc-tab-dabg: #344197;
  --inc-tab-dacolor: #FFFFFF;

  --inc-button-bg: #28ACE2;
  --inc-button-color: #FFFFFF;
  --inc-btn-outline-bg: #0C0E24;
  --inc-btn-outline-color: #8696CC;
  --inc-btn-outline-border: #1D234A;
  --inc-btn-outline-hbg: #1f234b;
  --inc-btn-outline-hcolor: #CCCCCC;
  --inc-btn-outline-hborder: #1D234A;
  --inc-btn-fill-bg: #344197;
  --inc-btn-fill-color: #FFFFFF;
  --inc-btn-fill-hbg: #212D7D;
  --inc-btn-fill-hcolor: #FFFFFF;

  --inc-permission-border: #343c69;
  --inc-permission-nbg: #101639;
  --inc-permission-ncolor: #FFFFFF;
  --inc-permission-nborder: #343c69;

  --inc-fault-sep-color:rgba(45, 52, 92, 0.5);

  --inc-dropdown-hbg:#141732;
  --inc-dropdown-hcolor:#FFFFFF;

  --inc-online:#032b06;
  --inc-offline:#390505;
  --inc-awaiting:#5d4004;

  --inc-accordian-bg:#020515;
  --inc-accordian-border:rgba(45, 52, 92, 0.5);

  --inc-trouble-bg: #020515;

  --inc-sidenav-active-bg:#344197;
  --inc-sidenav-active-color:#FFFFFF;

  --inc-icon-color:brightness(0) saturate(100%) invert(97%) sepia(5%) saturate(435%) hue-rotate(265deg) brightness(116%) contrast(100%);
  --inc-icon-h-color:brightness(0) saturate(100%) invert(97%) sepia(5%) saturate(435%) hue-rotate(265deg) brightness(116%) contrast(100%);

  --inc-schedule-bg:#0e122a;
  --inc-time-color:#343c69;
  --inc-schedule-name-color:#FFFFFF;

  --inc-notification-bg:#0e122a;
  --inc-notification-ibg:#1f234b;
  --inc-notification-color:#8D9DD5;

  --inc-label-color: #ffffff;

  --inc-black:#FFFFFF;
  --inc-unread-border:transparent;

  --inc-device-border:#222750;

  --inc-accordian-arrow:invert(71%) sepia(9%) saturate(1605%) hue-rotate(191deg) brightness(87%) contrast(91%);

  --inc-link-border:#323752;
  --inc-link-slave-title-bg:#363e76;
}

/* ======BUTTON====== */
.btn-primary {
  background: var(--inc-button-bg);
  color: var(--inc-button-color);
}

.btn-outline,
.device-filter .filter-button button.lineBtn {
  border: solid 1px var(--inc-btn-outline-border);
  background: var(--inc-btn-outline-bg);
  color: var(--inc-btn-outline-color);
}

.btn-outline:hover {
  background: var(--inc-btn-outline-hbg);
  color: var(--inc-btn-outline-hcolor);
  border: solid 1px var(--inc-btn-outline-hborder);
}

.btn-fill,
.device-filter .filter-button button.fillBtn {
  background: var(--inc-btn-fill-bg);
  color: var(--inc-btn-fill-color);
}

.btn-fill:hover {
  background: var(--inc-btn-fill-hbg);
  color: var(--inc-btn-fill-hcolor);
}

/* .device-filter .filter-button button.lineBtn {
  color: #067AE6;
  background: #FFFFFF;
  border: solid 2px #067AE6;
}

.device-filter .filter-button button.fillBtn {
  background: #067AE6;
  color: #FFFFFF;
} */

.MuiSvgIcon-root{
  color: var(--inc-nav-color);
}
/* ======BODY====== */
body {
  background: var(--inc-body-bg);
  color: var(--inc-body-color);
}

.inc-sys-health {
  color: var(--inc-health-color);
}

.inc-sys-health img {
  filter: var(--inc-health-img);
}

.dr-status {
  background: var(--inc-dr-status);
}

/* ======NAVIGATION====== */
.navbar-menu {
  background: var(--inc-Nav-bg);
}
.nav-tabs{
  border-bottom:solid 1px var(--inc-border);
}
.navbar-brand-box {
  border-bottom: solid 1px var(--inc-border);
}

.inc-navigation a,
.inc-navigation button {
  color: var(--inc-nav-color);
}

.inc-navigation a:hover,
.inc-navigation a.active,
.inc-navigation .dropdown:has(.dropdown-menu .active) button,
.inc-navigation .dropdown.open:has(.dropdown-menu) button,
.inc-navigation .dropdown:hover button {
  background: var(--inc-nav-active-bg);
  border-left: solid 5px var(--inc-nav-active-border);
  color: var(--inc-nav-active-color);
}

.inc-navigation .dropdown-menu {
  background: var(--inc-nav-dropdown-bg);
}

.inc-navigation a i.icon,
.inc-navigation .dropdown button i.icon {
  filter: var(--inc-nav-icon-color)
}

.inc-navigation a:hover i.icon,
.inc-navigation a.active i.icon,
.inc-navigation .dropdown:hover button i.icon,
.inc-navigation .dropdown.open button i.icon {
  filter: var(--inc-nav-icon-hcolor)
}

.icon.notifications,
.drop img,
.client-connection img {
  filter: var(--inc-nav-icon-color)
}
.icon.notifications span{
  filter: none;
}

/* ======HEADER====== */
#top-bar {
  background: var(--inc-header-bg);
}

.header-title {
  color: var(--inc-header-title-color);
}

.name-post strong {
  color: var(--inc-header-user-color);
}

.ice-switch input:checked+.slider {
  background: var(--inc-header-toggle-a-bg);
}

/* ======MODAL====== */
.inc-modal {
  background: var(--inc-modal-bg);
  border: solid 2px var(--inc-modal-border);
  color: var(--inc-modal-color);
}

.modal-content {
  background: var(--inc-modal-bg);
  border: solid 2px var(--inc-modal-border);
  color: var(--inc-modal-color);
}

.modal-title {
  color: var(--inc-modal-title-color);
}

.inc-alert .modal-dialog .modal-content h3{
  color: var(--inc-alert-title-color);
}

.modal-header {
  border-bottom: solid 1px var(--inc-border);
}

.modal-footer {
  border-top: solid 1px var(--inc-border);
}

.btn-close {
  filter: var(--inc-nav-icon-color);
}

/* ======TAB====== */
.nav-underline .nav-link:focus,
.nav-underline .nav-link:hover,
.nav-underline .nav-link {
  color: var(--inc-tab-color);
}

.nav-underline .nav-link.active {
  color: var(--inc-tab-acolor);
  border-color: var(--inc-tab-aborder);
}

#roleTab button {
  background: var(--inc-tab-dbg);
  color: var(--inc-tab-dcolor);
}

#roleTab button.active{
  background: var(--inc-tab-dabg);
  color: var(--inc-tab-dacolor);
  border: 0px;
}

/* ======TABLE====== */
.inc-card-table table tbody tr:nth-child(even) {
  background: var(--inc-table-bg)
}

.inc-card-table table tbody tr:nth-child(odd) {
  background: transparent
}

.inc-card-table table tbody tr:hover {
  background: var(--inc-table-hbg)
}

.inc-card-table table {
  color: var(--inc-table-color);
}

.inc-card-table table thead {
  border-bottom: solid 1px var(--inc-border);
}

.inc-card-table table thead th {
  background: var(--inc-table-th-bg);
  color: var(--inc-table-th-color);
}

td .button-group button,
.schedule-button button {
  color: var(--inc-table-icon-color);
}
td .button-group button:disabled{
  color: var(--inc-table-icon-dscolor);
}

td .button-group button:hover,
.schedule-button button:hover {
  color: var(--inc-table-icon-hcolor);
}

td .searchBox {
  background: var(--inc-search-bg);
  border: solid 1px var(--inc-search-border-color);
}

td .searchBox:focus {
  border: solid 1px var(--inc-search-border-color);
}
.list-search{
  background: var(--inc-search-bg) url(../images/search.svg) 5px 5px no-repeat;
  border: solid 1px var(--inc-textbox-border);
  background-size: 20px;
}
.inc-card-table table thead th i {
  color: var(--inc-table-sort-icon);
}

.inc-card-table table thead th i:hover {
  color: var(--inc-table-sort-hicon);
}

.device-info div span{
  color:var(--inc-info-color);
}

/* ==============TEXTBOX========== */
.form-control,
.form-control:focus,
.form-select,
.form-select:focus {
  background-color: var(--inc-textbox-bg);
  border: solid 1px var(--inc-textbox-border);
  color: var(--inc-textbox-color);
}
.MuiInputBase-root,
.MuiInputBase-root:hover,
.MuiInputBase-root:focus {
  background-color: var(--inc-textbox-bg) !important; 
  border: solid 1px var(--inc-textbox-border) !important; 
  color: var(--inc-textbox-color) !important;
  outline: none !important;
}
.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border: 0px;
}
.custom-mail span[class*='css-1f'] + div{
  background-color: var(--inc-textbox-bg);
  border: solid 1px var(--inc-textbox-border);  
  box-shadow: none;
  border-radius: 0.375rem;
  height: 40px;
}
.custom-mail span[class*='css-1f'] + div input{
  color: var(--inc-textbox-color) !important;
}
.custom-mail span[class*='css-1f'] + div + div{
  background-color: var(--inc-textbox-bg);
  border: solid 1px var(--inc-textbox-border);
  color: var(--inc-textbox-color);
}
.custom-mail span[class*='css-1f'] + div + div div{
  background-color: var(--inc-textbox-bg);
  color: var(--inc-textbox-color);
}
::placeholder {
  color: var(--inc-placeholder-color) !important;
}
.form-control:disabled{
  background-color: var(--inc-disable-bg);
  color: var(--inc-disable-color);
}
/* ======INC CARD====== */
.inc-card {
  background: var(--inc-card-bg);
}

.inc-card-title h3,
.inc-card-header .inc-card-title {
  color: var(--inc-card-title-color);
}

.inc-card-header,
.inc-card-title,
.search-border td {
  border-bottom: solid 1px var(--inc-border);
}

.ota-card {
  background: var(--inc-otacard-bg);
  border: 1px solid var(--inc-otacard-border);
}

.card-top h3 {
  color: var(--inc-audit-title-color);
}

.permission-type ul li {
  border: 1px solid var(--inc-permission-border);
}

.permission-name ul li.active,
.permission-name ul li:hover {
  background: var(--inc-permission-nbg);
  border: 1px solid var(--inc-permission-nborder);
  color: var(--inc-permission-ncolor);
}

.permission-type ul li:has(input[type=checkbox]:checked) {
  background: var(--inc-permission-nbg);
  border: 1px solid var(--inc-permission-nborder);
}

/* ====== FORM ASSETS ====== */
.icon-btn {
  background: var(--inc-icon-btn-bg);
  color: var(--inc-icon-btn-color);
}

.icon-btn:hover {
  background: var(--inc-icon-btn-hbg);
  color: var(--inc-icon-btn-hcolor);
}

.text-btn {
  background: var(--inc-text-btn-bg);
  color: var(--inc-text-btn-color);
}

.text-btn:hover,
.text-btn.primary-btn {
  background: var(--inc-primary-btn-bg);
  color: var(--inc-primary-btn-color);
}

label.checkbox span {
  border: 1px solid var(--inc-checkbox-border);
}

label.checkbox input[type=checkbox]:checked~span {
  background: var(--inc-checkbox-bg) url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+) 50% 40% no-repeat;
  border: 1px solid var(--inc-checkbox-bg);
}

label.checkbox input[type=checkbox]:focus~span {
  border: 1px solid var(--inc-checkbox-border);
}

.fault-legends div::before{
  background:var(--inc-fault-sep-color);
}

.read-only-input, .text-btn.primary-btn.disabled, .text-btn.primary-btn:disabled{
  background-color:var(--inc-disable-bg);
  color:var(--inc-disable-color);
}

.text-btn.disabled, .text-btn:disabled,
.text-btn.disabled:hover, .text-btn:disabled:hover {
  color: var(--inc-page-disable-color);
  background-color: var(--inc-page-bg);
}

/* ==========DROPDOWN========== */
.dropdown-menu{
  background: var(--inc-card-bg);
  border:solid 1px var(--inc-border);  
}
.dropdown-item{
  color:var(--inc-body-color);
}
.dropdown-item:focus, .dropdown-item:hover{
  color:var(--inc-dropdown-hcolor);
  background-color:var(--inc-dropdown-hbg);
}

.inc-card-table table tbody td .d-status.online{
  background:var(--inc-online);
}
.inc-card-table table tbody td .d-status.offline{
  background:var(--inc-offline);
}
.inc-card-table table tbody td .d-status.awaitingConnection{
  background:var(--inc-awaiting);
}
.device-info div strong{
  color: var(--inc-table-th-color);
}


/* ================REPORT================ */
.inc-report .report-section{
  background-color: var(--inc-report-card-bg);
}
.inc-report .report-tab div span,
.inc-report .report-tab div strong{
  color: var(--inc-step-color);
}
.report-container h4{
  color: var(--inc-step-color);
}
.report-section .report-button{
  border-top:solid 1px var(--inc-border);
}

/* ================ACCORDION================ */
.accordion-item{
  background-color: var(--inc-accordian-bg);
  border:solid 1px var(--inc-border);
}
.accordion-button:not(.collapsed){
  background-color: var(--inc-header-bg);
  border-bottom: solid 1px var(--inc-accordian-border);
}
.accordion-button{
  background-color: var(--inc-header-bg);
  color: var(--inc-header-title-color);
}
.accordion-button:not(.collapsed){
  color: var(--inc-header-title-color);
  box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--inc-header-bg);
}
.accordion-button::after{
  filter: var(--inc-accordian-arrow);
}
.accordion-footer{
  border-top: solid 1px var(--inc-border);
}
/* ============PAGINATION============ */
.pagination-container {
  border-top: solid 1px var(--inc-border);
}
.pagination button {
  border: 1px solid var(--inc-page-border);
  background-color: var(--inc-page-bg);
}
.pagination button:hover {
  background: var(--inc-primary-btn-bg);
  color: var(--inc-primary-btn-color);
}
.pagination button.active {
  background: var(--inc-primary-btn-bg);
  color: var(--inc-primary-btn-color);
}
.pagination button:disabled {
  color: var(--inc-page-disable-color);
  background-color: var(--inc-page-bg);
}

.inc-card-footer{
  border-top:solid 1px var(--inc-border);
}


.troubleshoot-option label{
  background-color:var(--inc-trouble-bg);
}
.troubleshoot-option label h4{
  color:var(--inc-header-title-color) ;
}
.sublabel strong{
  color: var(--inc-table-th-color);
}
.sublabel{
  background-color:var(--inc-trouble-bg);
}

.client-con-type label{
  background: var(--inc-report-card-bg);
}
.no-data-text{
  color: var(--inc-body-color);
}

.round-border{
  border: solid 1px var(--inc-border);
}

.inc-side-tab{
  border-right:solid 1px var(--inc-border);
}

/* =================WIFI SIDE TAB============== */
.inc-side-tab ul li:hover button,
.inc-side-tab ul li button.active{
    background:var(--inc-sidenav-active-bg);
    color: var(--inc-sidenav-active-color);
}

.add-ssid-body h4{
  color: var(--inc-table-th-color);
}
.switch-box strong{
  color:var(--inc-modal-color);
}
.radio-group .form-check label{
  color:var(--inc-modal-color);
}
.slider{
  background: var(--inc-toggle-off-bg);
}
.border-line:after{
  background-color:var(--inc-border);
}

.inc-card-title .inc-card-button button .icon{
  filter:var(--inc-icon-color) ;
}
.inc-card-title .inc-card-button button:hover .icon{
  filter:var(--inc-icon-h-color) ;
}

.wifi-footer{
  border-top: solid 1px var(--inc-border);
}

/* ==============WIFI SCHEDULE============ */
.schedule-wrapper .schedule-item,
.domain-list{
  background-color:var(--inc-schedule-bg);
}
.schedule-wrapper .schedule-item .schedule-icon{
  border-right: solid 1px var(--inc-time-border);
}
.schedule-icon{
  color:var(--inc-time-color);
}
.schedule-detail h5{
  color: var(--inc-schedule-name-color);
}
.schedule-time-box{
  background-color:var(--inc-schedule-bg);
}
.schedule-days .sc-day-title,
.schedule-time-box input{
  border-bottom: solid 1px var(--inc-time-border);
}

.tab-inside-tab{
    border-bottom: solid 1px var(--inc-border);
}

.tab-container-title.top-border{
  border-top: solid 1px var(--inc-border);
}

.tab-form input[type='text'] {
  border: solid 1px var(--inc-textbox-border);
}

.filter-title {
  border-bottom: solid 1px var(--inc-border);
}
.filter-title span,
.device-filter .filter-body {
  color: var(--inc-body-color);
}
.filter-body .cal-input,
.filter-body select{  
  border: 1px solid var(--inc-textbox-border);
  background-color: var(--inc-textbox-bg);
}


.top-notification,
.device-filter{
  background: var(--inc-card-bg);
  border:solid 1px var(--inc-border);
}
.top-notification .notification-title{
  border-bottom:solid 1px var(--inc-border)
}
.top-notification .notification-item{
  border-top:solid 1px var(--inc-border)
}
.top-notification .notification-item:hover{
  background-color: var(--inc-dropdown-hbg);
}
.top-notification .view-all-notification,
.notification-footer{
  border-top:solid 1px var(--inc-border)
}
.top-notification .view-all-notification:hover,
.notification-footer span:hover{
  background-color: var(--inc-dropdown-hbg);
}

.notification-container .notification-list{
  background-color: var(--inc-notification-bg);
}
.notification-list .n-icon span{
  background-color:var(--inc-notification-ibg);
}
.notification-list .n-info h6{
  color: var(--inc-table-th-color);
}
.notification-list .n-info h6 small,
.notification-list .n-info p{
  color: var(--inc-notification-color);
}

.poe-schdule-time span,
.poe-schdule-time strong{
  color:var(--inc-notification-color)
}
.schedule-action{
  border-bottom: solid 1px var(--inc-border);
}
.dashboard-device-info .dashboard-device-detail .detail-list span {
  color: var(--inc-label-color);
}

.header-title p a {
  color: var(--inc-checkbox-bg);
}
.topology-label{
  color: var(--inc-table-th-color);
}

.radius-help-modal ul li strong{
  color: var(--inc-table-th-color);
}
.radius-device{
  background-color: var(--inc-accordian-bg);
  border: solid 1px var(--inc-border);
}
.radius-device-title{
  border-bottom: solid 1px var(--inc-border);
}
.radius-device-title a{
  color: var(--inc-checkbox-bg);
  text-decoration: none;
}

.or-seperator::before{
  border-bottom: solid 1px var(--inc-border);
}
.or-seperator span{
  background-color: var(--inc-border);
}


.polygon{
  border-top:solid 1px var(--inc-device-border);
}
.port-box{
  border: solid 1px var(--inc-device-border);
}
.port-box ul li{
  border: solid 1px var(--inc-device-border);
}
.polygon::before{
  background-color: var(--inc-device-border);
}
.polygon::after{
  background-color: var(--inc-device-border);
}


.weekdays label span{
  border: 1px solid var(--inc-border);
  color: var(--inc-body-color);
}


/* ==============MODULE LINK============ */
.master-module {
  background: var(--inc-header-bg);
  border: 1px solid var(--inc-border);
}
.master-module .mm-title{
  background: var(--inc-text-btn-bg);
    border-bottom: 1px solid var(--inc-border);
}
.master-module .link{
  background: #C2E0FC;
}
.slave-module {
  background: var(--inc-header-bg);
    border: 1px solid  var(--inc-link-border);
}
.slave-module .sm-title {
  background:var(--inc-link-slave-title-bg) ;
    border-bottom: 1px solid var(--inc-link-border);
}

.custom-slider {
  background: var(--inc-border);
}
.custom-track{
  background: var(--inc-primary-btn-bg);
}
.custom-track-1{
  background: var(--inc-border);
}
.icon-btn:disabled ,
.icon-btn:disabled:hover{
  color: var(--inc-table-icon-dscolor);
  background: var(--inc-icon-btn-bg);
}
